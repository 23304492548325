import { SET_BUSY } from 'client/constants';

const initialState = true;

export default function isBusyReducer(state = initialState, { type, payload }) {
	switch (type) {
		case SET_BUSY:
			return payload;

		default:
			return state;
	}
}
