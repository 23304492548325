import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { detectIE } from 'client/helpers/detect-ie';

import { Responsive } from 'common/components';

import * as css from './AccessibilityIcon.scss';

class AccessibilityIcon extends Component {
	static propTypes = {
		onClick: PropTypes.func,
		className: PropTypes.string,
	};

	static defaultProps = {
		onClick: () => {},
		className: '',
	};

	// eslint-disable-next-line class-methods-use-this
	get accessablityBtn() {
		return document.querySelector('#INDmenu-btn');
	}

	onClick = event => {
		this.accessablityBtn.click();
		this.props.onClick(event);
	};

	onMouseEnter = () => {
		let eventFocus;

		if (Responsive.isMatching(Responsive.DESKTOP)) {
			if (detectIE() > 0) {
				eventFocus = document.createEvent('Event');
				eventFocus.initEvent('focus', false, true);
			} else {
				eventFocus = new Event('focus');
			}

			this.accessablityBtn.dispatchEvent(eventFocus);
		}
	};

	onMouseLeave = () => {
		let eventBlur;

		if (detectIE() > 0) {
			eventBlur = document.createEvent('Event');
			eventBlur.initEvent('blur', false, true);
		} else {
			eventBlur = new Event('blur');
		}

		this.accessablityBtn.dispatchEvent(eventBlur);
	};

	render() {
		const { className } = this.props;
		return (
			<button
				type="button"
				onClick={this.onClick}
				onMouseEnter={this.onMouseEnter}
				onMouseLeave={this.onMouseLeave}
				className={classNames(css.button, className)}
			>
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
					<g id="prefix__accessibility_icon" data-name="accessibility icon" transform="translate(-1840 -930)">
						<g
							id="prefix__Ellipse_10"
							fill="none"
							stroke="#1d1c1c"
							strokeWidth="2px"
							data-name="Ellipse 10"
							transform="translate(1840 930)"
						>
							<circle cx="25" cy="25" r="25" stroke="none" />
							<circle cx="25" cy="25" r="24" />
						</g>
						<path
							id="prefix__wheelchair-access"
							fill="#1d1c1c"
							fillRule="evenodd"
							// eslint-disable-next-line max-len
							d="M15.3 15.867a1.039 1.039 0 0 1 .644.42.888.888 0 0 1 .124.742 6.3 6.3 0 0 1-.3.939 7.481 7.481 0 0 1-1.188 2.126 7.848 7.848 0 0 1-1.78 1.644 8.476 8.476 0 0 1-2.2 1.051 8.265 8.265 0 0 1-2.476.371 8.078 8.078 0 0 1-3.157-.618 8.286 8.286 0 0 1-2.582-1.682 8.008 8.008 0 0 1-1.746-2.484A7.311 7.311 0 0 1 0 15.323a7.515 7.515 0 0 1 .371-2.348 7.637 7.637 0 0 1 1.065-2.1 8.122 8.122 0 0 1 1.655-1.707A8.4 8.4 0 0 1 5.22 7.982a1.107 1.107 0 0 1 .78.037.937.937 0 0 1-.062 1.767 6.384 6.384 0 0 0-1.609.9 6.1 6.1 0 0 0-1.238 1.285 5.961 5.961 0 0 0-.8 1.582 5.71 5.71 0 0 0 .2 4.079A5.878 5.878 0 0 0 3.8 19.5a6.374 6.374 0 0 0 1.94 1.262 6.057 6.057 0 0 0 2.377.469 6.245 6.245 0 0 0 3.529-1.088 6.338 6.338 0 0 0 1.337-1.236 5.583 5.583 0 0 0 .9-1.582 3.607 3.607 0 0 0 .223-.717 1.012 1.012 0 0 1 .433-.618.926.926 0 0 1 .761-.123zm6.24 3.782a1.144 1.144 0 0 1 .124.519 1.124 1.124 0 0 1-.359.84 1.188 1.188 0 0 1-.854.346 1.222 1.222 0 0 1-.693-.2 1.236 1.236 0 0 1-.446-.519l-3.269-5.685H10.42a1.176 1.176 0 0 1-.272.025 1.611 1.611 0 0 1-1.04-.358 1.477 1.477 0 0 1-.545-.927L7.2 7.141h.025a.641.641 0 0 1-.025-.2 1.716 1.716 0 0 1 .546-1.271 1.841 1.841 0 0 1 1.337-.531 1.8 1.8 0 0 1 1.127.371 1.835 1.835 0 0 1 .656.939l.025.074a.1.1 0 0 0 .012.062.1.1 0 0 1 .012.062l.223 1.137H15a.824.824 0 0 1 .446.124.971.971 0 0 1 .4 1.335.89.89 0 0 1-.421.4 1.13 1.13 0 0 1-.57.1h-3.296l.5 2.423h4.209a1.649 1.649 0 0 1 .891.247 1.551 1.551 0 0 1 .594.667l3.788 6.575zM8.439 4.62a2.248 2.248 0 0 1-.916-.185A2.339 2.339 0 0 1 6.3 3.211a2.238 2.238 0 0 1-.189-.911 2.178 2.178 0 0 1 .186-.89A2.339 2.339 0 0 1 7.523.183 2.247 2.247 0 0 1 8.439 0a2.187 2.187 0 0 1 .891.185 2.543 2.543 0 0 1 .743.494 2.118 2.118 0 0 1 .5.729 2.31 2.31 0 0 1 .173.89 2.258 2.258 0 0 1-.669 1.644 2.2 2.2 0 0 1-1.638.678z"
							transform="translate(1854.171 943.336)"
						/>
					</g>
				</svg>
			</button>
		);
	}
}

export default AccessibilityIcon;
