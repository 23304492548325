/* eslint-disable max-len */
// second screen

const statistic = {
	en: {
		title: 'Video data:',
		items: [
			{
				title: 'Over 330',
				subTitle: `Million Minutes <br/> watched monthly `,
				icon: '/statisticVideo/desktop_icon.png',
				name: 'video',
			},
			{
				title: 'Over 25',
				subTitle: `Million <br/> Videos watched`,
				icon: '/statisticVideo/phone_icon.png',
				name: 'phone',
			},
		],
	},
	he: {
		title: 'נתוני וידאו',
		items: [
			{
				title1: `מספר צפיות`,
				title: `למעלה מ-25`,
				subTitle: 'מיליון צפיות בחודש',
				icon: '/statisticVideo/phone_icon.png',
				name: 'phone',
			},
			{
				title: 'מעל 330',
				subTitle: 'מיליון דקות צפייה <br/> חודשיות בממוצע ',
				icon: '/statisticVideo/desktop_icon.png',
				name: 'video',
			},
		],
	},
};

export default statistic;
