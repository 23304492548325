import React from 'react';
import PT from 'prop-types';
import classNames from 'classnames';

import * as css from './Container.scss';

const Container = ({ className, children, ...rest }) => (
	<div className={classNames(css.container, className)} {...rest}>
		{children}
	</div>
);

Container.propTypes = {
	className: PT.string,
	children: PT.node,
};

Container.defaultProps = {
	className: '',
	children: null,
};

export default Container;
