import * as React from 'react';
import { Switch } from 'react-router-dom';
import PT from 'prop-types';

export default class RouterSwitch extends React.Component {
	static propTypes = {
		onUpdate: PT.func.isRequired,
	};

	componentDidUpdate() {
		this.props.onUpdate();
	}

	render() {
		return <Switch {...this.props} />;
	}
}
