/* eslint-disable max-len */
const graphics = {
	he: {
		title: 'אפליקציית התוכן הגדולה ביותר',
		app: {
			banner: {
				bannerImg: '/graphics/banner-img.png',
				title: `מקום ראשון
			בארץ`,
				number: '1',
				clarification: `*נתוני בנצ'מרק similarweb `,
			},
			installAppInfo: [
				{
					title: 'מותקנת <span>באופן בלעדי</span> על',
					icon: '/graphics/phone_icon.png',
					value: 32,
					text: 'מהמכשירים בישראל',
				},
				{
					title: 'מותקנת אצל',
					icon: '',
					value: 51,
					text: 'מהישראלים',
				},
			],
			desktopDevices: {
				title: 'מכשירים שולחניים',
				subTitle: 'כניסות חודשיות במיליונים',
				icon: '/graphics/screen_icon.png',
				values: [
					{
						name: 'ynet',
						value: 24.18,
					},
					{
						name: 'וואלה!News',
						value: 16.54,
					},
					{
						name: 'Mako',
						value: 9.57,
					},
					{
						name: 'N12',
						value: 1.3,
					},
				],
			},
			mobileDevices: {
				title: 'מכשירים ניידים | מובייל ווב ואנדרואיד',
				subTitle: 'כניסות חודשיות במיליונים',
				icon: '/graphics/mobile-screen_icon.png',
				values: [
					{
						name: 'ynet',
						value: 39.89,
					},
					{
						name: 'Mako',
						value: 19.36,
					},
					{
						name: `וואלה!News`,
						value: 16.853,
					},
					{
						name: 'N12',
						value: 4.403,
					},
				],
			},
			table: {
				title: `האפליקציות
			הפופולריות בישראל`,
				tableContent: ['ynet', 'N12', 'Walla!News', 'חמ"ל', 'הארץ'],
				notes: 'מבוסס על נתוני Android Usage Rank של similarweb',
			},
			installAppInfoNotes: ` הנתונים באדיבות חברת Start App המפתחת מוצרים וטכנולוגיות מבוססי ביג דאטה בתחום הפרסום במובייל `,
			devicesNotes: `מבוסס על נתוני בנצ'מרק של similarweb ממוצע חודשי לחודשים ינואר-יוני 2020 , ישראל בלבד. נתוני אפליקציה- אנדרואיד בלבד. `,
		},
		leaderbords: {
			newsSites: {
				title: 'אתרי תוכן חדשותיים בארץ',
				items: ['ynet', `וואלה!News`, 'Mako', 'Haaretz', 'Globes'],
				notes: ` מבוסס על נתוני חודש  יוני 2020 של Similarweb דסקטופ ומובייל ווב.`,
			},
			searchSites: {
				title: 'אתרים מובילים בחיפוש אורגני בארץ',
				items: ['YouTube', 'Wikipedia', 'Facebook', 'ynet', `וואלה!News`],
				notes: ` מבוסס על נתוני חודש  יוני 2020 של Similarweb דסקטופ בלבד.`,
			},
		},
	},
	en: {
		title: "Israel's largest content app",
		app: {
			banner: {
				bannerImg: '/graphics/banner-img.png',
				title: `First Place
				In the country`,
				number: '1',
				clarification: `*Data by Similarweb`,
			},
			installAppInfo: [
				{
					title: 'Installed <span>Exclusively</span> on',
					icon: '/graphics/phone_icon.png',
					value: 32,
					text: 'of mobile phones in the local market',
				},
				{
					title: 'Installed on',
					icon: '',
					value: 51,
					text: "of Israeli's mobile phones",
				},
			],
			desktopDevices: {
				title: 'Desktop users',
				subTitle: 'monthly visits in Millions',
				icon: '/graphics/screen_icon.png',
				values: [
					{
						name: 'ynet',
						value: 24.18,
					},
					{
						name: 'Walla!news',
						value: 16.54,
					},
					{
						name: 'Mako',
						value: 9.57,
					},
					{
						name: 'N12',
						value: 1.3,
					},
				],
			},
			mobileDevices: {
				title: 'Mobile | mobile web + Android',
				subTitle: 'monthly visits in Millions',
				icon: '/graphics/mobile-screen_icon.png',
				values: [
					{
						name: 'ynet',
						value: 39.89,
					},
					{
						name: 'Mako',
						value: 19.36,
					},
					{
						name: `Walla!news`,
						value: 16.853,
					},
					{
						name: 'N12',
						value: 4.403,
					},
				],
			},
			table: {
				title: `Israel's most
				popular apps`,
				tableContent: ['ynet', 'N12', 'Walla!News', 'Hama"l', 'Haaretz'],
				notes: `installed exclusively on 32% of local mobile phones. <br/>
					Installed on 51% of local mobile phones`,
			},
			installAppInfoNotes: `Data provided by Start App, developer of mobile advertising products and technologies relying on big data`,
			devicesNotes: `Avg use for the months January-June 2020; App data based on Android users only. Data by Similarweb`,
		},
		leaderbords: {
			newsSites: {
				title: 'News websites in Israel, desktop and mobile web ',
				items: ['ynet', `Walla!news`, 'Mako', 'Haaretz', 'Globes'],
				notes: `Desktop and mobile web, Based on June 2020 traffic; data by Similarweb`,
			},
			searchSites: {
				title: 'Leading websites by organic search in Israel, desktop only',
				items: ['YouTube', 'Wikipedia', 'Facebook', 'ynet', `Walla!news`],
				notes: `Desktop and mobile web, Based on June 2020 traffic; data by Similarweb.`,
			},
		},
	},
	ar: {
		title: 'تطبيق المحتوى الأكبر​',
		app: {
			banner: {
				bannerImg: '/graphics/banner-img.png',
				title: `المكان الأول في البلاد`,
				number: '1',
				clarification: `معدل معطيات Similarweb`,
			},
			installAppInfo: [
				{
					title: 'موجودة بشكل حصري على',
					icon: '/graphics/phone_icon.png',
					value: 32,
					text: 'من الأجهزة في إسرائيل',
				},
				{
					title: 'موجودة لدى',
					icon: '',
					value: 51,
					text: 'من الإسرائيليين',
				},
			],
			desktopDevices: {
				title: 'أجهزة ثابتة',
				subTitle: 'دخول شهري بالملايين ​',
				icon: '/graphics/screen_icon.png',
				values: [
					{
						name: 'ynet',
						value: 24.18,
					},
					{
						name: 'Walla!news',
						value: 16.54,
					},
					{
						name: 'Mako',
						value: 9.57,
					},
					{
						name: 'N12',
						value: 1.3,
					},
				],
			},
			mobileDevices: {
				title: 'أجهزة نقّالة| خلوي، ويب وأندرويد​',
				subTitle: 'دخول شهري بالملايين ​',
				icon: '/graphics/mobile-screen_icon.png',
				values: [
					{
						name: 'ynet',
						value: 39.89,
					},
					{
						name: 'Mako',
						value: 19.36,
					},
					{
						name: `Walla!news`,
						value: 16.853,
					},
					{
						name: 'N12',
						value: 4.403,
					},
				],
			},
			table: {
				title: `التطبيقات\n  الأكثر  شيوعًا في إسرائيل`,
				tableContent: ['ynet', 'N12', 'Walla!News', 'حامال', 'هآريتس​'],
				notes: ` * اعتمادًا على معطيات Android Usage Rank التابع ل similarweb`,
			},
			installAppInfoNotes: `* المعطيات بلطف من Start App التي تطوّر منتجات وتقنيات تعتمد "بيج داتا " في مجال الاعلانات على الموبايل `,
			devicesNotes: `* اعتمادًا على معطيات معيار similarweb ,إسرائيل فقط. معطيات اندرويد فقط 2020, معدل الاشهر كانون الثاني- حزيران`,
		},
		leaderbords: {
			newsSites: {
				title: 'مواقع مضامين اخبارية في البلاد ',
				items: ['ynet', `Walla!news`, 'Mako', 'Haaretz', 'Globes'],
				notes: `اعتمادًا على معطيات شهر حزيران Similarweb-2020 وديسك توب وموبايل ويب.`,
			},
			searchSites: {
				title: 'اعتمادًا على معطيات بحث غير مُمول في البلاد ​',
				items: ['YouTube', 'Wikipedia', 'Facebook', 'ynet', `Walla!news`],
				notes: `اعتمادًا على معطيات شهر حزيران Similarweb-2020 وديسك توب فقط.`,
			},
		},
	},
};

export default graphics;
