/* eslint-disable max-len */
// first screen

const advertasingArticles = {
	he: {
		title: 'כתבות פרסומיות',
		text: ` מאפשרות למפרסם לעלות עם כתבת טקסט, וידאו או שילוב ביניהן, אשר יקודם ברחבי
			האתר בכלים פרסומיים. פעילות זו מוגדרת מראש להקלקות יוניק. באמצעות מיטב
			הכתבים והעורכים אנו מתאימים את הכתבה לשפה של האתר, ודואגים לשמור על
			אחדות בין הכתבות, מערכתיות ופרסומיות כאחת. הכתבה מוצגת בעמוד עם נראות של
			ynet תוך ציון העובדה שמדובר בתוכן פרסומי. ניתן להוסיף תמונות או לינקים לעמוד
			המפרסם.`,
	},
	en: {
		title: 'Branded content',
		text: `The client can publish text, video, or a combination of the two, that will be distributed using our advertising tools throughout the site. Those campaigns are defined as unique click-based activities. We use the best creatives and editors, so the content feels as native as possible within the guidelines. Articles are published on a page while mentioning the fact that it is branded content. The client can add images and links to his website.`,
	},
	ar: {
		title: 'تقارير إعلانية ​',
		text: `\n تُتيح للمعلن نشر تقارير نصّية, فيديو أو دمج كليهما, الذي يتم دفعه في أرجاء
		الموقع بالأدوات الإعلانية. هذه العملية مُعرفة منذ البداية للنّقرات الفريدة.

		بواسطة أفضل المراسلين والمحرّرين نُلائم التقرير للغة الموقع ونهتم بالحفاظ على
		الوحدة بين التقارير, من حيث النظامية والاعلان على حد سواء. التقرير يعرض على
		 صفحة تُبين Ynet وسط ذكر ملاحظة أن الفحوى إعلاني, يمكن إضافة صور او رابط
		لصفحة المعلن.`,
	},
	items: [
		{
			imageSrc: '/advertasing_articles/image4.jpg',
			logoSrc: '/advertasing_articles/logo4.png',
			text: `ארומה ישראל משיקה קפסולות קפה
                    חדשות`,
			link: 'https://www.ynet.co.il/articles/0,7340,L-5321481,00.html',
		},
		{
			imageSrc: '/advertasing_articles/image3.jpg',
			logoSrc: '/advertasing_articles/logo3.png',
			text: 'תתפנקו על בטוח',
			link: 'https://www.ynet.co.il/articles/0,7340,L-5069381,00.html',
		},
		{
			imageSrc: '/advertasing_articles/image2.jpg',
			logoSrc: '/advertasing_articles/logo2.png',
			text: ` חמישה דברים שלא ידעתם שאפשר
                    לעשות בציריך`,
			link: 'https://www.ynet.co.il/articles/0,7340,L-5635226,00.html',
		},
		{
			imageSrc: '/advertasing_articles/image1.jpg',
			logoSrc: '/advertasing_articles/logo1.png',
			text: ` הגיע הגיל שאפשר להתחיל - כל
                    היתרונות של שילוב דגנים בטעימות`,
			link: 'https://www.ynet.co.il/articles/0,7340,L-5553052,00.html',
		},
	],
};

export default advertasingArticles;
