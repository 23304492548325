/* eslint-disable import/default, import/no-webpack-loader-syntax, global-require */

import 'babel-polyfill';
import * as React from 'react';
import { render } from 'react-dom';

import { setDebug } from 'common/utils';
import Root from 'client/Root';
import store, { history } from 'client/store';

import 'client/styles/main.scss';

setDebug();

const renderApp = App => {
	render(<App store={store} history={history} />, document.getElementById('root'));
};

window.addEventListener('load', () => {
	renderApp(Root);
});

if (module.hot) {
	module.hot.accept('../src/routes/client/Root', () => {
		renderApp(require('../src/routes/client/Root').default);
	});
}
