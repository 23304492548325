const partners = {
	en: {
		title: 'Digital assets, the Yedioth Ahronoth group',
	},
	he: {
		title: 'הנכסים הדיגיטליים של קבוצת ידיעות אחרונות',
	},
	ar: {
		title: 'الثروات الرقمية لمجموعة يديعوت أحرونوت ',
	},
	logoSrc: '/partners/logo.png',
	items: [
		{
			link: 'https://www.yedioth.co.il/',
			logoSrc: '/partners/1.png',
		},
		{
			link: 'https://b.ynet.co.il/',
			logoSrc: '/partners/2.png',
		},
		{
			link: 'https://www.pro.co.il/',
			logoSrc: '/partners/3.png',
		},
		{
			link: 'https://www.calcalist.co.il/home/0,7340,L-8,00.html',
			logoSrc: '/partners/4.png',
		},
		{
			link: 'https://www.e-vrit.co.il/',
			logoSrc: '/partners/5.png',
		},
		{
			link: 'https://www.alljobs.co.il/',
			logoSrc: '/partners/6.png',
		},
		{
			link: 'https://www.winwin.co.il/Homepage/Page.aspx',
			logoSrc: '/partners/7.png',
		},
		{
			link: 'https://www.ynetespanol.com/',
			logoSrc: '/partners/8.png',
		},
		{
			link: 'https://www.ynetnews.com/category/3083',
			logoSrc: '/partners/9.png',
		},
		{
			link: 'https://premium.ynet.co.il/Web/Manage/Main',
			logoSrc: '/partners/10.png',
		},
		{
			link: 'https://pplus.ynet.co.il/home/0,7340,L-11211,00.html',
			logoSrc: '/partners/11.png',
		},
		{
			link: 'https://www.ynet.co.il/home/0,7340,L-3456,00.html',
			logoSrc: '/partners/12.png',
		},
		{
			link: 'https://xnet.ynet.co.il/home/0,7340,L-11946,00.html',
			logoSrc: '/partners/13.png',
		},
		{
			link: 'https://www.ynet.co.il/home/0,7340,L-1209,00.html',
			logoSrc: '/partners/14.png',
		},
		{
			link: 'https://xnet.ynet.co.il/home/0,7340,L-11946,00.html',
			logoSrc: '/partners/15.png',
		},
		{
			link: 'https://www.mynet.co.il/Common/home/',
			logoSrc: '/partners/16.png',
		},
		{
			link: 'https://www.blender.co.il/#',
			logoSrc: '/partners/17.png',
		},
		{
			link: 'https://yschool.co.il/',
			logoSrc: '/partners/18.png',
		},
		{
			link: 'https://www.ynetshops.co.il/',
			logoSrc: '/partners/19.png',
		},
		{
			link: 'https://www.frogi.co.il/',
			logoSrc: '/partners/20.png',
		},
	],
};

export default partners;
