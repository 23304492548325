const consumption = {
	en: {
		titleTop: `Video consumption`,
		titleBottom: `the Yedioth Ahronoth group`,
		name: 'en',
	},
	he: {
		titleTop: `וידאו בקבוצת`,
		titleBottom: `ידיעות אחרונות`,
		name: 'he',
	},
	logoSrc: '/consumption/desktop_icon.png',
	ynetSrc: '/consumption/ynet_icon.png',
	items: [
		{
			link: 'https://b.ynet.co.il/',
			logoSrc: '/consumption/one_icon.png',
		},
		{
			link: 'https://www.yedioth.co.il/',
			logoSrc: '/consumption/becth_icon.png',
		},
		{
			link: 'https://b.ynet.co.il/',
			logoSrc: '/consumption/calcalist_icon.png',
		},
		{
			link: 'https://www.yedioth.co.il/',
			logoSrc: '/consumption/woman_icon.png',
		},
		{
			link: 'https://b.ynet.co.il/',
			logoSrc: '/consumption/peruvian_icon.png',
		},
		{
			link: 'https://www.yedioth.co.il/',
			logoSrc: '/consumption/icon.png',
		},
	],
};

export default consumption;
