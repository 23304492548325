// first screen

const general = {
	menuItems: [],
	he: {
		ynetLogo: {
			text: `מפרסמים`,
			img: `/logo-ynet.png`,
		},
	},
	en: {
		ynetLogo: {
			text: `publishers`,
			img: `/logo-ynet.png`,
		},
	},
	ar: {
		ynetLogo: {
			text: `publishers`,
			img: `/logo-ynet.png`,
		},
	},
};

export default general;
