/* eslint-disable import/prefer-default-export */
import _ from 'lodash';

export const fetchMiddleware = fetchModule => store => next => action => {
	if (action.url) {
		const { url, method = 'POST', payload = {}, type, onFail, onSuccess, secure = false } = action;
		const apiRequest = async () => {
			const token = _.get(store.getState(), 'user.token', '');
			const headers = { 'Content-Type': 'application/json' };
			const options = { mode: 'cors' };
			let result = null;

			if (secure) {
				_.set(headers, 'Authorization', `Bearer ${token}`);
			}

			if (!_.isEmpty(payload)) {
				_.set(options, 'body', JSON.stringify(payload));
			}

			_.set(options, 'method', method);
			_.set(options, 'headers', headers);

			result = await fetchModule.send(url, options);

			if (result.success && onSuccess) {
				onSuccess(result);
			} else if (!result.success && onFail) {
				onFail(result);
			}

			return result;
		};

		next({ type, payload });

		return apiRequest();
	}

	return next(action);
};
