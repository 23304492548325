/* eslint-disable no-console */
/* eslint-disable prefer-template, max-len */
import _ from 'lodash';
import appConfig from 'application-config';
import debug from 'debug';

import { Responsive } from 'common/components';
import { SHARE_CONSTANTS } from 'client/constants';

const log = debug('app:client:share');

/**
 * Share buttons event handler
 *
 * @param {{}} options
 * @param {String} options.type
 * @param {String} options.mail
 * @param {String} options.img
 * @param {String} options.title
 * @param {String} options.description
 * @param {Function} cb
 */
export function share(options, cb = _.noop) {
	const defaultOptions = {
		type: '',
		mail: '',
		title: document.querySelector('meta[property="og:title"]').getAttribute('content'),
		description: document.querySelector('meta[property="og:description"]').getAttribute('content'),
		url: `${document
			.querySelector('meta[property="og:url"]')
			.getAttribute('content')}?utm_source=Email%26utm_medium=Share`,
		img: document.querySelector('meta[property="og:image"]').getAttribute('content'), // 1200x630
	};

	const currentOptions = Object.assign({}, defaultOptions, options);
	const { type, mail, title, description, url, img, categoryId } = currentOptions;
	const encode = encodeURIComponent;
	const emailTitle = ` Mako | 102FM | מצעד העשור הישראלי`;
	const emailText = `בחרו את השירים הכי טובים של העשור הישראלי ותוכלו לזכות בחבילה של הופעות חיות`;
	const whatsAppText = `${title}\n${description}`;

	log('share options %O', currentOptions);

	if (type === SHARE_CONSTANTS.MAIL) {
		window.location.href =
			'mailto:' + mail + '?subject=' + encode(emailTitle) + '&body=' + emailText + '\n\n' + url;
	}

	if (type === SHARE_CONSTANTS.FACEBOOK) {
		const fbURL = 'https://www.facebook.com/sharer.php?u=';
		const domain = `${_.get(
			appConfig,
			['domains', 'client', 'hostname'],
			'NO HOSTNAME'
		)}?utm_source=Facebook&utm_medium=Share`;
		let finalURL;

		if (categoryId) {
			finalURL = `${fbURL}${encode(`${domain}/share/fb?categoryId=${categoryId}&v=6`)}`;
		} else {
			finalURL = `${fbURL}${encode(`${domain}/share/fb?v=6`)}`;
		}

		window.open(finalURL, 'sharer', 'width=555,height=600');
	}

	if (type === SHARE_CONSTANTS.WHATSAPP) {
		const urlWhatsapp = `${window.location.href}?utm_source=Whatsapp&utm_medium=Share`;

		if (Responsive.isMatching(Responsive.DESKTOP)) {
			window.open(
				`https://web.whatsapp.com/send?text=${encode(`${whatsAppText}\n${urlWhatsapp}`)}&img="${encode(img)}"`,
				'_blank'
			);
		} else {
			window.location.href = 'whatsapp://send?text=' + encode(`${whatsAppText}\n${window.location.href}`);
		}
	}

	if (type === SHARE_CONSTANTS.TWITTER) {
		const twURL = 'https://twitter.com/intent/tweet?url=';
		const domain = `${_.get(
			appConfig,
			['domains', 'client', 'hostname'],
			'NO HOSTNAME'
		)}?utm_source=Twitter&utm_medium=Share`;
		const finalURL = `${twURL}${encode(`${domain}/share/fb`)}?text=${encode(title + '\n' + description + '\n')}`; // WORKING

		const shareWindow = window.open(finalURL, 'ShareOnTwitter', 'width=555,height=600');
		shareWindow.opener = null;
	}

	cb();
}
