import intro from './intro';
import statistic from './statistic';
import graphics from './graphics';
import general from './general';
import partners from './partners';
import cooperation from './cooperation';
import programmaticDepartment from './programmaticDepartment';
import advertasingArticles from './advertasingArticles';
import inStreamPost from './inStreamPost';
import statisticVideo from './statisticVideo';
import consumption from './consumption';
import purchase from './purchase';
import systemPartition from './systemPartition';

export default {
	intro,
	statistic,
	general,
	graphics,
	partners,
	cooperation,
	programmaticDepartment,
	advertasingArticles,
	inStreamPost,
	statisticVideo,
	consumption,
	purchase,
	systemPartition,
};
