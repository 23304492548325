/* eslint-disable react/no-danger, indent, react/jsx-indent */
/* eslint-disable react/jsx-curly-brace-presence */
import * as React from 'react';
import _ from 'lodash';
import ScrollAnimation from 'react-animate-on-scroll';
import data from 'client/data';
import PT from 'prop-types';
import classNames from 'classnames';
import { IMAGES_PATH } from 'client/constants';

import * as css from './Consumption.scss';

export default class Consumption extends React.PureComponent {
	static propTypes = {
		className: PT.string,
		activeLanguage: PT.string,
	};

	static defaultProps = {
		className: '',
		activeLanguage: '',
	};

	rednerAddsSection = () => {
		const { consumption } = data;
		const { className, activeLanguage } = this.props;
		if (!activeLanguage) return null;
		return (
			<div className={classNames(css.adds, className)}>
				<div className={css.content}>
					<div className={classNames(css.addsTitle, css[consumption[activeLanguage].name])}>
						<p
							className={css.addsTitleTop}
							dangerouslySetInnerHTML={{ __html: consumption[activeLanguage].titleTop }}
						/>
						<p
							className={css.addsTitleBottom}
							dangerouslySetInnerHTML={{ __html: consumption[activeLanguage].titleBottom }}
						/>
					</div>
					<p className={css.addsLogoWrapper}>
						<img className={css.addsLogo} src={`${IMAGES_PATH}${consumption.logoSrc}`} alt="logo" />
					</p>
					<div className={css.addsLogoWrapper}>
						<img className={css.addsYnet} src={`${IMAGES_PATH}${consumption.ynetSrc}`} alt="ynet" />
					</div>
					<div className={css.addsItems}>
						{_.map(consumption.items, (partner, i) => (
							<div key={`partners-item-${i}`} className={css.item}>
								<img src={`${IMAGES_PATH}${partner.logoSrc}`} alt="" />
							</div>
						))}
					</div>
				</div>
			</div>
		);
	};

	render() {
		return (
			<div id="group-assets">
				<ScrollAnimation animateOnce animateIn="fadeIn">
					{this.rednerAddsSection()}
				</ScrollAnimation>
			</div>
		);
	}
}
