/* eslint-disable react/no-danger, indent, react/jsx-indent */
/* eslint-disable react/jsx-curly-brace-presence */
import * as React from 'react';
import PT from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import ScrollAnimation from 'react-animate-on-scroll';
import CountUp from 'react-countup';
import data from 'client/data';

import ynetLogo from 'assets/client/imgs/graphics/ynet_small_logo.png';
import { IMAGES_PATH } from 'client/constants';

import * as css from './Graphics.scss';

const scr = (scroll, element) => {
	const { top } = element.getBoundingClientRect();
	const halfHeight = window.innerHeight / 2;
	return top <= halfHeight && top > 0;
};

const appInfoRef = React.createRef();

export default class Graphics extends React.PureComponent {
	static propTypes = {
		className: PT.string,
		activeLanguage: PT.string,
	};

	static defaultProps = {
		className: '',
		activeLanguage: '',
	};

	state = {
		active: false,
	};

	// eslint-disable-next-line react/sort-comp
	handleScroll = () => {
		const scroll = window.pageYOffset || document.documentElement.scrollTop;
		if (scr(scroll, appInfoRef.current)) {
			this.setState({
				active: true,
			});
		}
	};

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll);
	}

	renderTableRows = () => {
		const { activeLanguage } = this.props;
		if (!activeLanguage) return null;

		const { graphics } = data;
		const { tableContent } = graphics[activeLanguage].app.table;

		return _.map(tableContent, (item, i) => (
			<div key={`table-row-${i}`} className={css.row}>
				<div className={css.col}>{i + 1}</div>
				<div className={css.col}>{item === 'ynet' ? <img src={ynetLogo} alt="" /> : item}</div>
			</div>
		));
	};

	renderNotes = notes => {
		return (
			<div className={css.notes}>
				<p dangerouslySetInnerHTML={{ __html: notes }}></p>
			</div>
		);
	};

	renderInstallAppInfo = () => {
		const { activeLanguage } = this.props;
		if (!activeLanguage) return null;

		const { graphics } = data;

		const { installAppInfo, installAppInfoNotes } = graphics[activeLanguage].app;

		return (
			<div className={css.appInfo} ref={appInfoRef}>
				<div className={css.installAppInfo}>
					{_.map(installAppInfo, (item, i) => (
						<div key={`inst-item-${i}`} className={css.item}>
							<p className={css.itemTitle} dangerouslySetInnerHTML={{ __html: item.title }} />
							<div className={css.itemContent}>
								{item.icon && (
									<div className={css.itemIcon}>
										<img src={`${IMAGES_PATH}${item.icon}`} alt="" />
									</div>
								)}
								<div>
									{!this.state.active ? (
										<div className={css.itemValue}>
											<span>1%</span>
										</div>
									) : (
										<CountUp start={0} end={item.value} delay={0}>
											{({ countUpRef }) => (
												<div>
													<div className={css.itemValue}>
														<span ref={countUpRef} />%
													</div>
												</div>
											)}
										</CountUp>
									)}
									<p className={css.itemBottomText}>{item.text}</p>
								</div>
							</div>
						</div>
					))}
				</div>
				{this.renderNotes(installAppInfoNotes)}
			</div>
		);
	};

	renderDeviceInfo = () => {
		const { activeLanguage } = this.props;
		if (!activeLanguage) return null;

		const { graphics } = data;
		const { desktopDevices, mobileDevices, devicesNotes } = graphics[activeLanguage].app;
		const designWCoefD = 2.15;
		const designWCoefM = 2.15;

		return (
			<div className={css.devices}>
				<div className={classNames(css.devicesItem, css.right)}>
					<div className={css.top}>
						<div className={css.topIcon}>
							<img src={`${IMAGES_PATH}${desktopDevices.icon}`} alt="" />
						</div>
						<div className={css.topContent}>
							<h5>{desktopDevices.title}</h5>
							<p>{desktopDevices.subTitle}</p>
						</div>
					</div>
					<div className={css.bottom}>
						{_.map(desktopDevices.values, (item, i) => (
							<div key={`btm-it-${i}`} className={css.bottomItem}>
								<div className={css.name}>
									{item.name === 'ynet' ? <img src={ynetLogo} alt="" /> : item.name}
								</div>
								<div className={css.graph}>
									<div className={css.graphBar} style={{ width: `${item.value * designWCoefD}%` }}>
										<div className={css.label}>{item.value}</div>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>

				<div className={css.devicesItem}>
					<div className={css.top}>
						<div className={css.topIcon}>
							<img src={`${IMAGES_PATH}${mobileDevices.icon}`} alt="" />
						</div>
						<div className={css.topContent}>
							<h5>{mobileDevices.title}</h5>
							<p>{mobileDevices.subTitle}</p>
						</div>
					</div>
					<div className={css.bottom}>
						{_.map(mobileDevices.values, (item, i) => (
							<div key={`btm-it-${i}`} className={css.bottomItem}>
								<div className={css.name}>
									{item.name === 'ynet' ? <img src={ynetLogo} alt="" /> : item.name}
								</div>
								<div className={css.graph}>
									<div className={css.graphBar} style={{ width: `${item.value * designWCoefM}%` }}>
										<div className={css.label}>{item.value}</div>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>

				{this.renderNotes(devicesNotes)}
			</div>
		);
	};

	renderBanner = () => {
		const { activeLanguage } = this.props;
		if (!activeLanguage) return null;
		const { graphics } = data;
		// const {
		// 	app: { banner },
		// } = graphics;
		const { banner } = graphics[activeLanguage].app;
		return (
			<div className={css.banner}>
				<img src={`${IMAGES_PATH}${banner.bannerImg}`} alt="banner" className={css.bannerImg} />
				<div className={css.bannerText}>
					<div className={css.number}>{banner.number}</div>
					<h6>{banner.title}</h6>
					<span className={css.clarification}>{banner.clarification}</span>
				</div>
			</div>
		);
	};

	renderLeaderboardSection = () => {
		const { activeLanguage } = this.props;
		if (!activeLanguage) return null;
		const { graphics } = data;
		const { newsSites, searchSites } = graphics[activeLanguage].leaderbords;
		return (
			<div className={css.leaderboards}>
				<div className={css.leaderboardsItem}>
					<h5 className={css.title}>{searchSites.title}</h5>
					<div className={css.table}>
						{_.map(searchSites.items, (name, index) => (
							<div
								key={`ld-item-${index}`}
								className={classNames(css.row, { [css.active]: name === 'ynet' })}
							>
								<div className={css.col}>{index + 1}</div>
								<div className={css.col}>{name === 'ynet' ? <img src={ynetLogo} alt="" /> : name}</div>
							</div>
						))}
					</div>
					{this.renderNotes(searchSites.notes)}
				</div>
				<div className={css.leaderboardsItem}>
					<h5 className={css.title}>{newsSites.title}</h5>
					<div className={css.table}>
						{_.map(newsSites.items, (name, index) => (
							<div
								key={`ld-item-${index}`}
								className={classNames(css.row, { [css.active]: name === 'ynet' })}
							>
								<div className={css.col}>{index + 1}</div>
								<div className={css.col}>{name === 'ynet' ? <img src={ynetLogo} alt="" /> : name}</div>
							</div>
						))}
					</div>
					{this.renderNotes(newsSites.notes)}
				</div>
			</div>
		);
	};

	renderAppSection = () => {
		const { activeLanguage } = this.props;
		if (!activeLanguage) return null;
		const { graphics } = data;
		const { table } = graphics[activeLanguage].app;
		return (
			<div className={css.app}>
				<div className={css.content}>
					<div className={css.title}>
						<h3>{graphics[activeLanguage].title}</h3>
					</div>
					<div className={css.appContent}>
						<div className={css.info}>
							{this.renderBanner()}
							{this.renderInstallAppInfo()}
						</div>
						<div className={css.tableSide}>
							<div className={css.tableSideTitle}>
								<h4>{table.title}</h4>
							</div>

							<div className={css.table}>{this.renderTableRows()}</div>
							{this.renderNotes(table.notes)}
						</div>
					</div>
					{this.renderDeviceInfo()}
					{this.renderLeaderboardSection()}
				</div>
			</div>
		);
	};

	render() {
		const { className } = this.props;

		return (
			<div id="app-rank" className={classNames(css.graphics, className)}>
				<ScrollAnimation animateOnce animateIn="fadeIn">
					{this.renderAppSection()}
				</ScrollAnimation>
			</div>
		);
	}
}
