/* eslint-disable react/no-danger, indent, react/jsx-indent */
/* eslint-disable react/jsx-curly-brace-presence */
import * as React from 'react';
import PT from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import ScrollAnimation from 'react-animate-on-scroll';
import data from 'client/data';

import { IMAGES_PATH } from 'client/constants';

import * as css from './ProgrammaticDepartment.scss';

export default class ProgrammaticDepartment extends React.PureComponent {
	static propTypes = {
		className: PT.string,
		activeLanguage: PT.string,
	};

	static defaultProps = {
		className: '',
		activeLanguage: '',
	};

	rednerDepartmentSection = () => {
		const { activeLanguage } = this.props;
		const { programmaticDepartment } = data;
		// const { title, items } = programmaticDepartment;
		if (!activeLanguage) return null;
		return (
			<div className={css.content}>
				<div className={css.info}>
					<h4 className={css.title}>{programmaticDepartment[activeLanguage].title}</h4>
					<div className={css.items}>
						{_.map(programmaticDepartment[activeLanguage].items, (item, i) => (
							<div key={`department-item-${i}`} className={css.item}>
								<div className={css.icon}>
									<img src={`${IMAGES_PATH}${item.iconSrc}`} alt="icon" />
								</div>
								<div className={css.text}>
									<p
										dangerouslySetInnerHTML={{
											__html: item.text,
										}}
									></p>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		);
	};

	render() {
		const { className } = this.props;
		return (
			<div
				id="programmatic-department"
				className={classNames(css.department, className)}
				style={{ backgroundImage: `url('/assets/client/imgs/programmaticDepartment/section-bg.jpg')` }}
			>
				<ScrollAnimation animateOnce animateIn="fadeIn">
					{this.rednerDepartmentSection()}
				</ScrollAnimation>
			</div>
		);
	}
}
