import React from 'react';
import { map } from 'lodash';
import PT from 'prop-types';
import classNames from 'classnames';
import { menu, linksList, otherMenuData } from 'client/data/menu';
import Responsive from 'common/components/Responsive';
import Logo from '../Header/Logo';
import Footer from '../Footer';
import AccessibilityIcon from '../AccessibilityIcon';
import * as css from './Menu.scss';

const Menu = ({ active, onClick, activeLanguage }) => {
	const handleOpen = e => {
		const { id } = e.currentTarget.dataset;

		if (id) {
			const element = document.getElementById(`${id}`);

			setTimeout(() => {
				element.scrollIntoView({ behavior: 'smooth', block: 'start' });
			}, 100);
		}
		onClick(id);
	};

	if (!activeLanguage) return null;

	return (
		<div className={classNames(css.menu, active ? css.active : '')} data-role="menu">
			<AccessibilityIcon className={css.accessibilityIcon} />
			<div className={css.close} onClick={handleOpen} />
			<Logo className={css.logo} />
			<div className={css.scrolledContent}>
				<div className={css.menuList}>
					<ul>
						{map(menu, (item, index) => (
							<>
								{item.text[activeLanguage] && (
									<li key={index}>
										<a
											href={`#${item.href}`}
											data-id={item.href}
											data-ga-title={item.gaTitle}
											onClick={handleOpen}
										>
											{item.text[activeLanguage]}
										</a>
									</li>
								)}
							</>
						))}
					</ul>
				</div>
				<div className={css.contactInfo}>
					<div className={css.linksSection}>
						<h4>{otherMenuData[activeLanguage].linksListTitle}</h4>
						<div className={css.linksList}>
							<ul>
								{map(linksList, (item, index) => (
									<li key={index}>
										<p>{item.text[activeLanguage]}</p>
										<a
											href={`${item.href}`}
											rel="noopener noreferrer"
											target="_blank"
											onClick={handleOpen}
										>
											{item.linkText[activeLanguage]}
										</a>
									</li>
								))}
							</ul>
						</div>
					</div>
					<div className={css.adress}>{otherMenuData[activeLanguage].adress}</div>
				</div>
				<Responsive query={Responsive.MOBILE}>
					<Footer />
				</Responsive>
			</div>
		</div>
	);
};

Menu.defaultProps = {
	active: false,
	activeLanguage: '',
};

Menu.propTypes = {
	active: PT.bool,
	onClick: PT.func.isRequired,
	activeLanguage: PT.string,
};

export default Menu;
