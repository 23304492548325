/* eslint-disable max-len */
// second screen

const statistic = {
	en: {
		title: 'Our users in numbers:',
		items: [
			{
				title: 'Over 2.5 million',
				titleMob: 'Over 2.5 million',
				subTitle: 'unique daily users',
				icon: '/statistic/people_icon.png',
				name: 'people',
			},
			{
				title: 'Over 21 million',
				subTitle: 'unique daily pageviews',
				icon: '/statistic/binocular_icon.png',
				name: 'view',
			},
			{
				title: 'Over 20 minutes',
				subTitle: 'average user session time',
				icon: '/statistic/clock_icon.png',
				name: 'clock',
			},
		],
		bottomNumber: '13',
		textBeforeNumber: 'Over',
		bottomTitle: 'average pageviews',
		bottomSubTitle: 'per user.',
		bottomIcon: '/statistic/eye_icon.png',
		notes: `Based on Google Analytics data for the Yedioth Ahronoth group, daily average, January-June 2020`,
	},
	he: {
		title: 'המשתמשים שלנו במספרים',
		items: [
			{
				title: 'מעל 2.5 מליון',
				titleMob: 'מעל 2 מליון',
				subTitle: 'משתמשים ביום',
				icon: '/statistic/people_icon.png',
				name: 'people',
			},
			{
				title: 'מעל 21 מליון',
				subTitle: 'צפיות בעמודים ביום',
				icon: '/statistic/binocular_icon.png',
				name: 'view',
			},
			{
				title: 'מעל 20 דקות',
				subTitle: 'שהייה בממוצע למשתמש',
				icon: '/statistic/clock_icon.png',
				name: 'clock',
			},
		],
		bottomNumber: '13',
		textBeforeNumber: 'מעל',
		bottomTitle: 'דפים נצפים',
		bottomSubTitle: 'בממוצע למשתמש',
		bottomIcon: '/statistic/eye_icon.png',
		notes: `מבוסס על נתוני גוגל אנליטיקס של קבוצת ידיעות אחרונות, בממוצע יומי לחודשים ינואר-יוני 2020`,
	},
	ar: {
		title: 'المستخدمون لموقعنا في أرقام​',
		items: [
			{
				title: 'أكثر من 2.5 مليون',
				titleMob: 'أكثر من 2.5 مليون',
				subTitle: 'مستخدم في اليوم',
				icon: '/statistic/people_icon.png',
				name: 'people',
			},
			{
				title: 'أكثر من 21 مليون',
				subTitle: 'مشاهدة في اليوم',
				icon: '/statistic/binocular_icon.png',
				name: 'view',
			},
			{
				title: 'أكثر من 20 دقيقة',
				subTitle: 'معدل مكوث للمستخدم',
				icon: '/statistic/clock_icon.png',
				name: 'clock',
			},
		],
		bottomNumber: '13',
		textBeforeNumber: 'أكثر من',
		bottomTitle: 'صفحة يتم تصفّحها ',
		bottomSubTitle: 'بالمعدل للمستخدم',
		bottomIcon: '/statistic/eye_icon.png',
		notes: `بناءً على بيانات جوجل أناليتكس لمجموعة يديعوت أحرونوت, المتوسط اليومي, كانون ثاني- حزيران 2020 `,
	},
};

export default statistic;
