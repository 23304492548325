/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-curly-brace-presence */
import * as React from 'react';
import PT from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';

import { Responsive } from 'common/components';
import data from 'client/data';
import ScrollAnimation from 'react-animate-on-scroll';
import { IMAGES_PATH } from 'client/constants';

import * as css from './SystemPartition.scss';

export default class SystemPartition extends React.PureComponent {
	static propTypes = {
		className: PT.string,
		activeLanguage: PT.string,
	};

	static defaultProps = {
		className: '',
		activeLanguage: '',
	};

	render() {
		const { className, activeLanguage } = this.props;
		const { systemPartition } = data;
		const isMobile = Responsive.isMatching(Responsive.MOBILE);
		if (!activeLanguage) return null;

		return (
			<div className={cn(css.systemPartition, className)} id="system-partition">
				<ScrollAnimation animateOnce animateIn="fadeIn">
					<div className={css.content}>
						{_.map(systemPartition[activeLanguage].items, (item, index) => (
							<div className={cn(css.container, index === 1 ? css.containerSecond : '')}>
								<div className={css.topTitle}>
									<div className={css.innerTopBlock}>
										<img src={`${IMAGES_PATH}${item.iconTop}`} className={css.topIcon} alt=""></img>
										<h4 className={css.title}>{item.title}</h4>
									</div>
									<p
										className={css.description}
										dangerouslySetInnerHTML={{
											__html: !isMobile ? item.subTitle : item.subTitleMobile,
										}}
									></p>
								</div>
								<ul className={css.list}>
									<li dangerouslySetInnerHTML={{ __html: item.list.text1 }}></li>
									<li dangerouslySetInnerHTML={{ __html: item.list.text2 }}></li>
									<li
										dangerouslySetInnerHTML={{
											__html: !isMobile ? item.list.text3 : item.list.text3Mobile,
										}}
									></li>
									{item.list.text4 && isMobile && (
										<li dangerouslySetInnerHTML={{ __html: item.list.text4 }}></li>
									)}
								</ul>
								<div className={css.notebookBlock}>
									<img
										className={css.notebookImg}
										src={`${IMAGES_PATH}${item.notebookImg}`}
										alt="notebook"
									></img>
									<div className={css.linkBlock}>
										<a
											className={css.link}
											href={item.link}
											target="_blank"
											rel="noopener noreferrer"
										>
											{item.linkTextTitle}
										</a>
										<span className={css.linkText}>{item.linkText}</span>
									</div>
								</div>
							</div>
						))}
					</div>
				</ScrollAnimation>
			</div>
		);
	}
}
