/* eslint-disable react/jsx-curly-brace-presence */
import React, { useState } from 'react';
import _ from 'lodash';

import { advertising } from 'client/data/advertising';
import { IMAGES_PATH } from 'client/constants';
import ScrollAnimation from 'react-animate-on-scroll';
import { Responsive } from 'common/components';
import classNames from 'classnames';
import * as css from './Advertising.scss';

const Advertising = props => {
	// const iframeSrc = 'https://www.ynet.co.il/home/0,7340,L-8,00.html';
	// eslint-disable-next-line react/prop-types
	const { activeLanguage } = props;
	// const { phone, laptop, mainTitle, info, mainTitleMobile } = advertising;
	const [mobileSrc, setMobileSrc] = useState(advertising[activeLanguage].phone.buttons[0].frameSrc);
	const [descktopSrc, setDescktopSrc] = useState(advertising[activeLanguage].laptop.buttons[0].frameSrc);

	const renderBtnsMobile = buttonsArray => {
		return _.map(buttonsArray, (item, index) => {
			return (
				<a
					href="#"
					target="_blank"
					rel="noopener noreferrer"
					key={`item-${index}`}
					data-device="mobile"
					data-src={item.frameSrc}
					className={classNames(
						css.button,
						{ [css.active]: item.frameSrc === mobileSrc },
						'mobile-iframe-link'
					)}
					onClick={btnClickListener}
				>
					{item.text}
				</a>
			);
		});
	};

	const renderBtns = buttonsArray => {
		return _.map(buttonsArray, (item, index) => {
			return (
				<a
					href="#"
					target="_blank"
					rel="noopener noreferrer"
					key={`item-${index}`}
					data-device="descktop"
					data-src={item.frameSrc}
					className={classNames(
						css.button,
						{ [css.active]: item.frameSrc === descktopSrc },
						'descktop-iframe-link'
					)}
					onClick={btnClickListener}
				>
					{item.text}
				</a>
			);
		});
	};

	const btnClickListener = e => {
		e.preventDefault();
		const src = e.target.getAttribute('data-src');
		const device = e.target.getAttribute('data-device');
		if (device === 'mobile') {
			setMobileSrc(src);
		} else {
			setDescktopSrc(src);
		}
	};
	if (!activeLanguage) return null;
	return (
		<div className={classNames(css.advertising)} id="leading-products">
			<div className={css.content}>
				{Responsive.isMatching(Responsive.MOBILE) && <h4>{advertising[activeLanguage].mainTitleMobile}</h4>}
				{Responsive.isMatching(Responsive.DESKTOP) && <h4>{advertising[activeLanguage].mainTitle}</h4>}
				<ScrollAnimation animateOnce animateIn="fadeIn">
					<div className={css.phoneSection}>
						<div className={css.iframeWrap}>
							<div
								className={classNames(css.iframe)}
								style={{ backgroundImage: `url(${IMAGES_PATH}${mobileSrc})` }}
							>
								{/* <iframe src={iframeSrc} title="site" className="mobile-iframe" /> */}
							</div>
							<div
								className={css.iframeBg}
								style={{
									backgroundImage: `url(${IMAGES_PATH}${advertising[activeLanguage].phone.iframeBg})`,
								}}
							/>
						</div>
						<div className={css.asideBtns}>
							<h6>{advertising[activeLanguage].phone.title}</h6>
							{renderBtnsMobile(advertising[activeLanguage].phone.buttons)}
						</div>
					</div>
				</ScrollAnimation>
				<ScrollAnimation animateOnce animateIn="fadeIn">
					<div className={css.laptopSection}>
						<div className={css.asideBtns}>
							<h6>{advertising[activeLanguage].laptop.title}</h6>
							{renderBtns(advertising[activeLanguage].laptop.buttons)}
						</div>
						<div className={css.iframeWrap}>
							<div
								className={classNames(css.iframe, {
									[css.lastItem]:
										descktopSrc === advertising[activeLanguage].laptop.buttons[6].frameSrc,
								})}
								style={{ backgroundImage: `url(${IMAGES_PATH}${descktopSrc})` }}
							>
								{/* Video */}
								{descktopSrc === advertising[activeLanguage].laptop.buttons[4].frameSrc && (
									<video
										muted
										autoPlay
										loop
										playsInline
										src={`${IMAGES_PATH}${descktopSrc}`}
										className={css.video}
									/>
								)}

								{/* Iframe */}
								{descktopSrc === advertising[activeLanguage].laptop.buttons[5].frameSrc && (
									<iframe src={descktopSrc} title="site" className="descktop-iframe" />
								)}
							</div>
							<div
								className={css.iframeBg}
								style={{
									// eslint-disable-next-line max-len
									backgroundImage: `url(${IMAGES_PATH}${advertising[activeLanguage].laptop.iframeBg})`,
								}}
							/>
						</div>
					</div>
				</ScrollAnimation>
				<div className={css.info}>
					{/* <a
						href={advertising[activeLanguage].info.btnLink}
						target="_blank"
						rel="noopener noreferrer"
						className={css.infoBtn}
					>
						{advertising[activeLanguage].info.btnLabel}
					</a> */}
					{Responsive.isMatching(Responsive.MOBILE) && <p>{advertising[activeLanguage].info.textMobile}</p>}
					{Responsive.isMatching(Responsive.DESKTOP) && <p>{advertising[activeLanguage].info.text}</p>}
				</div>
			</div>
		</div>
	);
};

export default Advertising;
