/* eslint-disable max-len */
const systemPartition = {
	he: {
		items: [
			{
				title: 'מדור',
				subTitle:
					'מדור מערכתי העוסק בסקירת והשוואת מוצרים. <br/>במסגרתו ניתנת האפשרות להטמעה של פיקסלים וצביעת הדאטה הרלוונטית לשימוש הלקוח.',
				subTitleMobile: `מדור מערכתי העוסק בסקירת והשוואת מוצרים. במסגרתו ניתנת האפשרות להטמעה של פיקסלים וצביעת הדאטה הרלוונטית לשימוש הלקוח.`,

				iconTop: '/systemPartition/topLeftImg.png',
				notebookImg: '/systemPartition/leftNotebook.png',
				link: 'https://www.ynet.co.il/economy/article/bjfgbuwfy',
				linkText: 'כתבה לדוגמה:',
				linkTextTitle: 'www.ynet.co.il/economy/article/bjfgbuwfy',

				list: {
					text1: 'פיקסל בודד לחודש – 5,000 ₪',
					text2: 'כרטיסיית 5 פיקסלים/חודשים – <span>K</span>15 <span>₪</span>',
					text3: 'פיקסל + קישור לעמוד נחיתה – <span>K</span>30 <span>₪</span>',
					text3Mobile: 'פיקסל + קישור לעמוד נחיתה – <span>K</span>30 <span>₪</span>',
					text4: '',
				},
			},
			{
				title: 'מדור',
				subTitle: `מענה לעסקים קטנים ובינוניים ויצירת נכס דיגיטלי עבורם <br/>בזירת ה -<span>BIZ</span>`,
				subTitleMobile: `מענה לעסקים קטנים ובינוניים ויצירת נכס<br/> דיגיטלי עבורם בזירת ה-<span>BIZ</span>`,
				iconTop: '/systemPartition/topRightImg.png',
				notebookImg: '/systemPartition/rightNotebook.png',
				link: 'https://biz.ynet.co.il',
				linkText: '',
				linkTextTitle: 'https://biz.ynet.co.il',

				list: {
					text1: 'תוכן פרסומי + קישור לעמוד נחיתה – 2,500 ₪',
					text2: 'תוכן פרסומי + פיקסל + 2 קישורים לעמוד נחיתה – 4,000 ₪',
					text3: 'תוכן פרסומי + פיקסל + 2 קישורים + התחייבות ל-3,500 יוניק – 7,500 ₪',
					text3Mobile: 'תוכן פרסומי + פיקסל + 2 קישורים + התחייבות ל-3,500',
					text4: 'יוניק – 7,500 ₪',
				},
			},
		],
	},
};

export default systemPartition;
