export const menu = [
	{
		href: 'intro',
		text: {
			he: 'ככה עושים חדשות',
			en: `That's how we make news`,
			ar: `هكذا نصنع الاخبار`,
		},
		gaTitle: 'אתר מפרסמים - פתיח',
	},
	{
		href: 'users-numbers',
		text: {
			he: `המשתמשים שלנו במספרים`,
			en: `Our users in numbers`,
			ar: `المستخدمون بالأرقام`,
		},
		gaTitle: 'אתר מפרסמים - המשתמשים שלנו במספרים',
	},
	{
		href: 'leading-products', // component: Advertising
		text: {
			he: 'מוצרי הפרסום המובילים שלנו',
			en: `Our leading advertising products`,
			ar: `أدوات الإعلان الرائدة لدينا`,
		},
		gaTitle: 'אתר מפרסמים - מוצרי הפרסום המובילים',
	},
	{
		href: 'in-stream-ad', // component: InStream
		text: {
			he: `וידאו וטכנולוגיות פרסום`,
			en: `Our top advertising tools`,
			ar: `فيديو وتكنولوجيا التسويق`,
		},
		gaTitle: 'אתר מפרסמים - מודעת In Stream',
	},
	{
		href: 'promotional-articles', // component: advertisingArticles
		text: {
			he: 'כתבות פרסומיות',
			en: `Branded content`,
			ar: `تقارير إعلانية`,
		},
		gaTitle: 'אתר מפרסמים - כתבות פרסומיות',
	},
	{
		href: 'system-partition', // component: systemPartition
		text: {
			he: 'מדורים מיוחדים',
			// en: `Branded content`,
			// ar: `تقارير إعلانية`,
		},
		gaTitle: 'מדורים מיוחדים',
	},
	{
		href: 'programmatic-department',
		text: {
			he: 'המחלקה הפרוגרמטית',
			en: `Our Programmatic department`,
			ar: `قسم البرمجة`,
		},
		gaTitle: 'אתר מפרסמים - המחלקה הפרוגרמטית',
	},
	{
		href: 'strategic-collaborations', // component: Cooparation
		text: {
			he: 'מחלקת שיתופי פעולה אסטרטגיים',
			en: `Our Strategic collaborations department`,
			ar: `قسم التعاونات الاستراتيجية`,
		},
		gaTitle: 'אתר מפרסמים - מחלקת שיתופי הפעולה האסטרטגיים',
	},
	{
		href: 'group-assets', // component: Partners
		text: {
			he: `הנכסים הדיגיטליים שלנו`,
			en: `Our digital assets`,
			ar: `ثرواتنا الرقمية`,
		},
		gaTitle: 'אתר מפרסמים - הנכסים הדיגיטליים של קבוצת ידיעות אחרונות',
	},
];

export const linksList = [
	{
		href: 'mailto:heli-p@ynet.co.il',
		linkText: {
			he: 'פנו למכירות',
			en: 'Contact the sales department',
			ar: 'توجهوا للمبيعات',
		},
		text: {
			he: 'לפרסום באתר,',
			en: 'For publication on the website',
			ar: 'للإعلان في الموقع,',
		},
	},
	{
		href: 'mailto:shelly-be@ynet.co.il',
		linkText: {
			he: 'פנו למחלקת התוכן',
			en: 'Contact the content department',
			ar: 'توجهوا لقسم المضامين',
		},
		text: {
			he: `לפרסום תוכן שיווקי באתר,`,
			en: 'For advertising marketing content on the site,',
			ar: 'لإعلان مضامين تسويقية في الموقع,',
		},
	},
	{
		href: 'mailto:Internet_Progrmative_Department@yedioth.co.il',
		linkText: {
			he: 'פנו למחלקה הפרוגרמטית',
			en: 'Contact the program department',
			ar: 'توجهوا لقسم البرمجة',
		},
		text: {
			he: 'לפרסום פרוגרמטי באתר,',
			en: 'For programmatic advertising on the site',
			ar: 'للإعلان المبرمج في الموقع,',
		},
	},
];

export const otherMenuData = {
	he: {
		linksListTitle: 'רוצים לפרסם אצלנו?',
		adress: `נח מוזס 1, ראשון לציון
		ת.ד 2568458
	טלפון: 03-6932424 \n	פקס: 03-6932466`,
	},
	en: {
		linksListTitle: 'Want advertising with us?',
		adress: `Noah Mozes 1, Rishon Lezion
		P.O: 2568458
		Phone: 03-6932424 \n	Fax: 03-6932466`,
	},
	ar: {
		linksListTitle: 'تريد الإعلان لدينا؟',
		adress: `ناح موزيس 1- ريشون لتسيون
			ص.ب: 2568458
			الهاتف: 03-6932424
			فاكس: 03-6932466`,
	},
};

export const analyticsLinksData = [
	{
		id: 'top',
		gaTitle: 'אתר מפרסמים - ראשי',
	},
	{
		id: 'intro',
		gaTitle: 'אתר מפרסמים - פתיח',
	},
	{
		id: 'video',
		gaTitle: 'אתר מפרסמים - וידאו',
	},
	{
		id: 'users-numbers',
		gaTitle: 'אתר מפרסמים - המשתמשים שלנו במספרים',
	},
	{
		id: 'app-rank',
		gaTitle: 'אתר מפרסמים - אפליקציית התוכן הגדולה ביותר',
	},
	{
		id: 'leading-products', // component: Advertising
		gaTitle: 'אתר מפרסמים - מוצרי הפרסום המובילים',
	},
	{
		id: 'in-stream-ad', // component: InStream
		gaTitle: 'אתר מפרסמים - מודעת In Stream',
	},
	{
		id: 'promotional-articles', // component: advertisingArticles
		gaTitle: 'אתר מפרסמים - כתבות פרסומיות',
	},
	{
		id: 'system-partition', // component: systemPartition
		gaTitle: 'מדורים מיוחדים',
	},
	{
		id: 'programmatic-department',
		gaTitle: 'אתר מפרסמים - המחלקה הפרוגרמטית',
	},
	{
		id: 'strategic-collaborations', // component: Cooparation
		gaTitle: 'אתר מפרסמים - מחלקת שיתופי הפעולה האסטרטגיים',
	},
	{
		id: 'group-assets', // component: Partners
		gaTitle: 'אתר מפרסמים - הנכסים הדיגיטליים של קבוצת ידיעות אחרונות',
	},
];
