/* eslint-disable max-len */
// first screen

const intro = {
	en: {
		infoTitle: 'Israel’s biggest online publisher',
		infoTextUnderTitle: `"easy to get in, difﬁcult to get out". That was the slogan that headlined ynet's launch. 20 years later, it's still one of the most prominent advantages we offer our clients: being able to reach all Israeli's, all the time.`,
		videoText: `
		<p> We proud ourselves with many years of intense journalistic work, that made us the country's agenda setters.</p>
		<p>We did that with outstanding journalistic credibility, covering a <span>wide range of subjects</span>;
		we deliver constant, <span> quick and reliable news reports</span>,
		high production skills and <span>original video content</span>.
		All those contributed to the steady growth of the number of our users.</p>`,
	},
	he: {
		infoTitle: 'אתר התוכן הגדול והמוביל בארץ',
		infoTextUnderTitle: `"קל להיכנס, קשה לצאת". זה היה הסלוגן שליווה השקת אתר ynet. \n היום, 20 שנה מאוחר יותר, המשפט הזה נשאר אחד היתרונות המרכזיים
		למפרסמים שלנו, ולכל מי שרוצה להגיע לכל הישראלים, כל הזמן.`,
		videoText: `
		<p> מאחורינו שנים ארוכות של עשייה עיתונאית אינטנסיבית,
		שהפכו אותנו לאתר שמכתיב את סדר היום הציבורי בארץ.</p>
		<p>זה קרה בזכות אמינות ומהימנות עיתונאית ללא פשרות,	המכסה <span>טווח רחב של תוכן</span>;
		<span>דיווח חדשותי מהיר,</span> בכל זמן ומכל מקום;
		יכולות הפקה ו<span>תכני וידאו איכותיים ומקוריים</span>;
		וגם בזכות מספר הגולשים שלנו, שרק הולך וגדל. </p>`,
	},
	ar: {
		infoTitle: ' موقع المضامين  الأكبر والرائد في البلاد​',
		infoTextUnderTitle:
			'"الدخول سهل, الخروج صعب" هذا هو الشعار الذي رافق موقع ​YNET منذ افتتاحه.\n اليوم وبعد 20 سنة, ما زالت هذه الجملة إحدى المزايا المركزية للمعلنين عندناـ ولكل من يريد \n الوصول الى جميع المستهلكين, في كل وقت.',
		videoText: `
		<p>من ورائنا سنوات طويلة من العمل الصحفي المكثّف,
		الذي جعلنا الموقع  المؤثر على النظام اليومي في البلاد.</p>
		<p>هذا حصل بفضل الأمانة والمهارة الصحفية بدون مهاونة, التي تغطي <span>مجالًا واسعًا من المحتويات</span>,
		<span>تقارير إخبارية سريعة </span>في كل وقت ومن أي مكان,
		قدرات اخراج و <span>مضامين فيديو ذات جودة واصلية</span>,
		وايضًا بفضل عدد المحررين في موقعنا الذي يتزايد باستمرار.</p>`,
	},
	iconScrollDown: `/intro/scroll-down.png`,
	playIcon: '/play-icon.png',
	gallery: [
		'1.jpg',
		'2.jpg',
		'3.jpg',
		'4.jpg',
		'5.jpg',
		'6.jpg',
		'7.jpg',
		'8.jpg',
		'9.jpg',
		'10.jpg',
		'11.jpg',
		'12.jpg',
		'13.jpg',
		'14.jpg',
		'15.jpg',
		'16.jpg',
		'17.jpg',
		'18.jpg',
	],
	unActiveGallery: [
		'19.jpg',
		'20.jpg',
		'21.jpg',
		'22.jpg',
		'23.jpg',
		'24.jpg',
		'25.jpg',
		'26.jpg',
		'27.jpg',
		'28.jpg',
		'29.jpg',
		'30.jpg',
		'31.jpg',
		'32.jpg',
	],
};

export default intro;
