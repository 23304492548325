/* eslint-disable max-len */

export const advertising = {
	en: {
		mainTitle: `Our top advertising tools`,
		mainTitleMobile: `Our top advertising tools`,
		phone: {
			title: 'Mobile',
			iframeBg: '/phone-iframe-bg.png',
			phoneCurtain: '/phone-curtain.png',
			buttons: [
				{
					frameSrc: `/frames_images/mobile-frame1.png`,
					text: 'Main Interstitial ad',
				},
				{
					frameSrc: `/frames_images/mobile-frame2.jpeg`,
					text: 'Inboard',
				},
				{
					frameSrc: `/frames_images/mobile-frame3.png`,
					text: 'Home page banner',
				},
				{
					frameSrc: `/frames_images/mobile-frame4.jpeg`,
					text: 'Feed',
				},
				{
					frameSrc: `/frames_images/mobile-frame5.png`,
					text: 'The 5th content strip',
				},
				{
					frameSrc: `/frames_images/mobile-frame6.jpg`,
					text: 'Block',
				},
			],
		},
		laptop: {
			title: 'Desktop',
			iframeBg: '/laptop.png',
			buttons: [
				{
					frameSrc: `/frames_images/descktop-frame1.png`,
					text: 'Premium',
				},
				{
					frameSrc: `/frames_images/descktop-frame2.png`,
					text: 'YBOX',
				},
				{
					frameSrc: `/frames_images/descktop-frame3.png`,
					text: 'Component',
				},
				{
					frameSrc: `/frames_images/descktop-frame4.png`,
					text: 'Ruler',
				},
				{
					frameSrc: `/frames_images/descktop-frame5.mp4`,
					text: 'ROS Interstitial ad',
				},
				{
					frameSrc: `https://showcaseapi.somplo.com/api/demo_prod/2?demo_id=60&demo_type=1`,
					text: `Video Inboard`,
				},
				{
					frameSrc: `/frames_images/descktop-frame7.png`,
					text: 'Elvis',
				},
			],
		},
		info: {
			text: `Can be purchased directly or through programmatic systems such as DV360; 360 media purchase with client control over reach, creative, sequence and audiences through IDX`,
			btnLabel: 'Click to view all advertising tools',
			btnLink: 'https://z.ynet.co.il/long/content/2020/YnetAdvertise/#/Desktop?externalurl=true',
			textMobile: `Can be purchased directly or through programmatic systems such as DV360; 360 media purchase with client control over reach, creative, sequence and audiences through IDX`,
		},
	},
	he: {
		mainTitle: `מוצרי הפרסום המובילים שלנו`,
		mainTitleMobile: `אפליקציית התוכן הגדולה ביותר`,
		phone: {
			title: 'Mobile',
			iframeBg: '/phone-iframe-bg.png',
			phoneCurtain: '/phone-curtain.png',
			buttons: [
				{
					frameSrc: `/frames_images/mobile-frame1.png`,
					text: 'מעברון ראשי פנימי',
				},
				{
					frameSrc: `/frames_images/mobile-frame2.jpeg`,
					text: 'אינבורד',
				},
				{
					frameSrc: `/frames_images/mobile-frame3.png`,
					text: 'באנר עמוד הבית',
				},
				{
					frameSrc: `/frames_images/mobile-frame4.jpeg`,
					text: 'פיד',
				},
				{
					frameSrc: `/frames_images/mobile-frame5.png`,
					text: 'סטריפ 5',
				},
				{
					frameSrc: `/frames_images/mobile-frame6.jpg`,
					text: 'קוביה',
				},
			],
		},
		laptop: {
			title: 'Desktop',
			iframeBg: '/laptop.png',
			buttons: [
				{
					frameSrc: `/frames_images/descktop-frame1.png`,
					text: 'פרימיום',
				},
				{
					frameSrc: `/frames_images/descktop-frame2.png`,
					text: 'YBOX',
				},
				{
					frameSrc: `/frames_images/descktop-frame3.png`,
					text: 'קומפוננטה',
				},
				{
					frameSrc: `/frames_images/descktop-frame4.png`,
					text: 'סרגל',
				},
				{
					frameSrc: `/frames_images/descktop-frame5.mp4`,
					text: 'מעברון ROS',
				},
				{
					frameSrc: `https://showcaseapi.somplo.com/api/demo_prod/2?demo_id=60&demo_type=1`,
					text: `אינבורד וידאו`,
				},
				{
					frameSrc: `/frames_images/descktop-frame7.png`,
					text: 'אלביס',
				},
			],
		},
		info: {
			text: ` ‏ניתן לרכוש בקניה ישירה או דרך מערכות קנייה פרוגרמטיות דוגמת 360DV
		 רכישת מדיה 360 עם שליטה בריץ', קריאייטיב, יצירת סיקוונס ורכישת קהלים דרך IDX`,
			btnLabel: 'לכל כלי הפרסום שלנו',
			btnLink: 'https://z.ynet.co.il/long/content/2020/YnetAdvertise/#/Desktop?externalurl=true',
			textMobile: ` ניתן לרכוש בקניה ישירה או דרך מערכות קנייה פרוגרמטיות
				דוגמת ,DV‏360. רכישת מדיה 360 עם שליטה בריץ
				.קריאייטיב, ויצירת סיקוונס`,
		},
	},
	ar: {
		mainTitle: `ادوات الإعلان الرائدة لدينا`,
		mainTitleMobile: `ادوات الإعلان الرائدة لدينا`,
		phone: {
			title: 'Mobile',
			iframeBg: '/phone-iframe-bg.png',
			phoneCurtain: '/phone-curtain.png',
			buttons: [
				{
					frameSrc: `/frames_images/mobile-frame1.png`,
					text: 'معبر رئيسي داخلي​',
				},
				{
					frameSrc: `/frames_images/mobile-frame2.jpeg`,
					text: 'Inboard',
				},
				{
					frameSrc: `/frames_images/mobile-frame3.png`,
					text: 'بانر صفحة البيت ​',
				},
				{
					frameSrc: `/frames_images/mobile-frame4.jpeg`,
					text: 'بين الأخبار ​- Feed',
				},
				{
					frameSrc: `/frames_images/mobile-frame5.png`,
					text: 'Strip 5​',
				},
				{
					frameSrc: `/frames_images/mobile-frame6.jpg`,
					text: 'مكعّب​',
				},
			],
		},
		laptop: {
			title: 'Desktop',
			iframeBg: '/laptop.png',
			buttons: [
				{
					frameSrc: `/frames_images/descktop-frame1.png`,
					text: 'بريميوم​',
				},
				{
					frameSrc: `/frames_images/descktop-frame2.png`,
					text: 'YBOX',
				},
				{
					frameSrc: `/frames_images/descktop-frame3.png`,
					text: 'مكعّب ​',
				},
				{
					frameSrc: `/frames_images/descktop-frame4.png`,
					text: 'مسطرة​',
				},
				{
					frameSrc: `/frames_images/descktop-frame5.mp4`,
					text: 'معبر ​ROS',
				},
				{
					frameSrc: `https://showcaseapi.somplo.com/api/demo_prod/2?demo_id=60&demo_type=1`,
					text: `فيديو داخلي`,
				},
				{
					frameSrc: `/frames_images/descktop-frame7.png`,
					text: 'إطار​',
				},
			],
		},
		info: {
			text: `يمكن شراء, بشكل مباشر او عن طريق برنامج أنظمة شراء 360DV \n شراء إعلانات 360 مع سيطرة، محتوى, انتاج إعلانات متتالية وشراء  \n هدف من خلال IDX`,
			btnLabel: 'لكل أدوات الإعلان خاصتنا​',
			btnLink: 'https://z.ynet.co.il/long/content/2020/YnetAdvertise/#/Desktop?externalurl=true',
			textMobile: `يمكن شراء, بشكل مباشر او عن طريق برنامج أنظمة شراء 360DV \n شراء إعلانات 360 مع سيطرة, محتوى, انتاج إعلانات متتالية وشراء  \n هدف من خلال IDX`,
		},
	},
};
