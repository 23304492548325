/* eslint-disable max-len */
// first screen

const inStreamPost = {
	en: {
		title: `Publishing an in-stream ad`,
		tabsNames: [{ name: 'Branded content' }, { name: 'Technology' }, { name: 'Video acquisition' }],
		tabsContent: [
			{
				title: 'Innovative video content',
				text: `Newsroom studio, magazine shows, outdoor <br/> productions etc. we have over 15 Million monthly<br/> views of quality content.`,
			},
			{
				title: `We serve ads using the most <br/> cutting-edge tools in the AdTech business`,
				text: `Options include serving ads by <br/> demography, using multiple tools: Pre-roll,<br/> overlay, True View and many other tools.`,
			},
			{
				text: `Recently we introduced the option to manage frequency between<br/> buying ads on Youtube and through the DV360 system. The client<br/> has the option to manage exposure frequency between ads on<br/> youtube and on ynet under one campaign.`,
				items: [
					{
						iconSrc: '/in_stream_post/tab-icon4.png',
						text: `Quality, above<br/> the fold serving`,
					},
					{
						iconSrc: '/in_stream_post/tab-icon5.png',
						text: `Advanced segmentation<br/> and serving`,
					},
					{
						iconSrc: '/in_stream_post/tab-icon3.png',
						text: `High viewability<br/> rates`,
					},
					{
						iconSrc: '/in_stream_post/tab-icon2.png',
						text: `The client can control and<br/> manage the campaign`,
					},
					{
						iconSrc: '/in_stream_post/tab-icon1.png',
						text: `First and third-party<br/> audience-based`,
					},
				],
			},
		],
	},
	he: {
		title: `פרסום מודעת <span>In Stream</span>`,
		tabsNames: [{ name: 'תכנים' }, { name: 'טכנולוגיה' }, { name: 'קנית וידאו' }],
		tabsContent: [
			{
				title: 'חדשנות ומקוריות בתכני הוידאו',
				text: `חדשות מהאולפן, תוכניות מגזין, הפקות מקור חדשות מהשטח,<br/>
                    ועוד. אנחנו מייצרים כל חודש מעל 15 מליון צפיות בתוכן איכותי
                    <br/>ומושקע.`,
			},
			{
				title: `הגשת פרסומות באמצעות
                    <br/>הטכנולוגיות המתקדמות בשוק`,
				text: `תוכלו להגיש פרסומות מפולחות על פי דמוגרפיה,
                    <br/> וכן שפע של אפשרויות פרסום:
                    <br/> פרירול, אובר-ליי ,True View ,ועוד`,
			},
			{
				text: `  לאחרונה התווספה האפשרות לניהול התדירות בין קניית YouTube
                    <br/> לקניית עסקאות דרך מערכת  Dv‏360. כך יתאפשר למפרסם לנהל את
                    <br/> תדירות החשיפה בין קניית וידאו ב YouTube-לקניית וידאו ב ynet-תחת
                    <br/>קמפיין אחד.`,
				items: [
					{
						iconSrc: '/in_stream_post/tab-icon4.png',
						text: `הגשה איכותית<br/>
                        above the fold`,
					},
					{
						iconSrc: '/in_stream_post/tab-icon5.png',
						text: `יכולות פילוח והגשה<br/>
                        מהמתקדמות בשוק`,
					},
					{
						iconSrc: '/in_stream_post/tab-icon3.png',
						text: `אחוזי צפיה<br/>
                            ויכולות צפיה גבוהים`,
					},
					{
						iconSrc: '/in_stream_post/tab-icon2.png',
						text: `שליטה וניהול<br/>
                            בצד המפרסם`,
					},
					{
						iconSrc: '/in_stream_post/tab-icon1.png',
						text: `קנייה מבוססת קהל –<br/>
					First and Third party`,
					},
				],
			},
		],
	},
	ar: {
		title: '​نشر إعلانات In Stream',
		tabsNames: [{ name: 'محتويات ' }, { name: 'تكنولوجيا' }, { name: 'شراء فيديو' }],
		tabsContent: [
			{
				title: 'تحديث وأصالة بمحتوى الفيديو ​',
				text: `اخبار من الاستوديو، برامج مجلة، انتاجات اصلية جديدة
					من الميدان وغيرها.
					اننا ننتج كل شهر اكثر من15 مليون مشاهدة لمحتوى\n  ذو جودة ومميز.`,
			},
			{
				title: `تقديم إعلانات بواسطة التكنولوجيا \n المتطورة في المجال`,
				text: `\nبإمكانكم تقديم إعلانات مجزأة بموجب التركيب السكني
						وايضًا الكثير من إمكانيات النشر
 						Pre-roll ,على الفيديو, True View, وغيرها `,
			},
			{
				text: ` مؤخرًا تم إضافة إدارة الوتيرة بين شراء YouTube وشراء
					صفقات عن طريق نظام DV360 - وهكذا يتسنى للمعلن إدارة
					وتيرة الكشف عن شراء فيديو في ال YouTube وبين شراء
					فيديو في Ynet تحت حملة واحدة`,
				items: [
					{
						iconSrc: '/in_stream_post/tab-icon4.png',
						text: `تقديم ذو جودة <br />above the fold`,
					},
					{
						iconSrc: '/in_stream_post/tab-icon5.png',
						text: `إمكانية تجزئة وتقديم <br /> المتطورة في السوق`,
					},
					{
						iconSrc: '/in_stream_post/tab-icon3.png',
						text: `نسبة مشاهدة وامكانية <br />مشاهدة عالية`,
					},
					{
						iconSrc: '/in_stream_post/tab-icon2.png',
						text: `سيطرة وإدارة من قبل <br /> المعلن`,
					},
					{
						iconSrc: '/in_stream_post/tab-icon1.png',
						text: `شراء يعتمد على الجمهور<br />first and third party`,
					},
				],
			},
		],
	},

	frameImageSrc: '/in_stream_post/phone.png',
	phoneCurtain: '/in_stream_post/phone-curtain.png',
	frameVideoSrc: '/video.mp4',
};

export default inStreamPost;
