import React, { useState, useEffect, createRef } from 'react';
import PT from 'prop-types';
import classNames from 'classnames';

import * as css from './ScrollBar.scss';

const ScrollBar = ({ className, contentWrapRef, contentRef, onProgressUpdate }) => {
	const [scrollingRatio, setScrollingRatio] = useState(1);
	const [scrollingMaxDistance, setScrollingMaxDistance] = useState(1);
	const scrollHandleRef = createRef();
	let scrollingDistance = 0;
	let targetDistance = 0;

	const updateScrollingDistance = distance => {
		const updatedScrollingProgress = distance / scrollingMaxDistance;
		const handleTargetY = updatedScrollingProgress * scrollingMaxDistance * scrollingRatio;
		const content = contentRef.current;
		const scrollHandle = scrollHandleRef.current;

		if (content) {
			content.style.transform = `translate3d(0, -${updatedScrollingProgress * scrollingMaxDistance}px, 0)`;
		}

		if (scrollHandle) {
			scrollHandle.style.transform = `translate3d(0, ${handleTargetY}px, 0)`;
		}

		scrollingDistance = distance;
		onProgressUpdate(updatedScrollingProgress);
	};

	const onWindowResize = () => {
		if (contentWrapRef.current && contentRef.current) {
			setScrollingMaxDistance(Math.max(contentRef.current.offsetHeight - contentWrapRef.current.offsetHeight, 0));
			setScrollingRatio(Math.min(contentWrapRef.current.offsetHeight / contentRef.current.offsetHeight, 1));
		}
	};

	const onMouseWheel = e => {
		const { deltaY } = e;
		// eslint-disable-next-line max-len
		targetDistance =
			scrollingDistance + (targetDistance < 0 || targetDistance > scrollingMaxDistance ? deltaY / 2 : deltaY);

		if (targetDistance < 0) {
			targetDistance = 0;
		}
		if (targetDistance > scrollingMaxDistance) {
			targetDistance = scrollingMaxDistance;
		}

		updateScrollingDistance(targetDistance);
	};

	useEffect(() => {
		updateScrollingDistance(0);
		onWindowResize();
		window.addEventListener('resize', onWindowResize);

		if (contentWrapRef.current) {
			contentWrapRef.current.addEventListener('mousewheel', onMouseWheel);
		}

		return () => {
			window.removeEventListener('resize', onWindowResize);
			if (contentWrapRef.current) {
				contentWrapRef.current.removeEventListener('mousewheel', onMouseWheel);
			}
		};
	}, [scrollingRatio, scrollingMaxDistance, contentRef.current]);

	return (
		<div className={classNames(css.scrollBar, className)}>
			<div className={css.scrollTrack} />
			<div className={css.scrollHandle} style={{ height: `${scrollingRatio * 100}%` }} ref={scrollHandleRef} />
		</div>
	);
};

ScrollBar.defaultProps = {
	className: '',
	contentWrapRef: null,
	contentRef: null,
	onProgressUpdate: () => {},
};

ScrollBar.propTypes = {
	className: PT.string,
	contentWrapRef: PT.shape({ current: PT.node }),
	contentRef: PT.shape({ current: PT.node }),
	onProgressUpdate: PT.func,
};

export default ScrollBar;
