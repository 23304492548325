import React from 'react';
import classNames from 'classnames';
import PT from 'prop-types';

import * as css from './Loader.scss';

const Loader = ({ active, mode, className }) => {
	if (window.outerWidth > 1024) {
		document.body.style.paddingRight = active
			? `${window.innerWidth - document.documentElement.clientWidth}px`
			: '';
		document.body.style.overflow = active ? 'hidden' : '';
	}

	return (
		<div className={classNames(css.loader, css[mode], { [css.show]: active }, className)}>
			<div className={css.ballScaleMultiple}>
				<div />
				<div />
				<div />
			</div>
		</div>
	);
};

Loader.WITH_OPACITY = 'withOpacity';
Loader.DEFAULT = '';

Loader.defaultProps = {
	className: '',
	active: true,
	mode: Loader.WITH_OPACITY,
};

Loader.propTypes = {
	className: PT.string,
	active: PT.bool,
	mode: PT.oneOf([Loader.WITH_OPACITY, Loader.DEFAULT]),
};

export default Loader;
