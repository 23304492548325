// routes
export const ROOT_ROUTE = '/';

export const SET_BUSY = 'SET_BUSY';

// others:
export const IS_DEVEL = process.env.NODE_ENV === 'development';
export const IMAGES_PATH = 'assets/client/imgs';
export const VIDEOS_PATH = 'assets/client/videos';

export const SHARE_CONSTANTS = {
	WHATSAPP: 'WhatsApp',
	TWITTER: 'Twitter',
	MAIL: 'Mail',
	FACEBOOK: 'Facebook',
};

export const MAKO_ANALYTICS_PAGE_IDS = {
	MAIN: 'HP',
	QUESTION: 'voteisrael',
	RESULT: 'result',
};

export const LANGUAGES = {
	HE: 'he',
	EN: 'en',
	AR: 'ar',
};

export const LANG_ROUTES = {
	HE: '/',
	EN: '/en',
	AR: '/ar',
};
