/* eslint-disable react/no-danger, indent, react/jsx-indent */
/* eslint-disable react/jsx-curly-brace-presence */
import * as React from 'react';
import _ from 'lodash';
import ScrollAnimation from 'react-animate-on-scroll';
import data from 'client/data';
import PT from 'prop-types';
import classNames from 'classnames';
import { IMAGES_PATH } from 'client/constants';

import * as css from './Partners.scss';

export default class Partners extends React.PureComponent {
	static propTypes = {
		className: PT.string,
		activeLanguage: PT.string,
	};

	static defaultProps = {
		className: '',
		activeLanguage: '',
	};

	rednerAddsSection = () => {
		const { partners } = data;
		const { className, activeLanguage } = this.props;
		if (!activeLanguage) return null;
		return (
			<div className={classNames(css.adds, className)}>
				<div className={css.content}>
					<h4
						className={css.addsTitle}
						dangerouslySetInnerHTML={{ __html: partners[activeLanguage].title }}
					></h4>
					<p className={css.addsLogoWrapper}>
						<img className={css.addsLogo} src={`${IMAGES_PATH}${partners.logoSrc}`} alt="logo" />
					</p>
					<div className={css.addsItems}>
						{_.map(partners.items, (partner, i) => (
							<a
								href={partner.link}
								target="_blank"
								rel="noopener noreferrer"
								key={`partners-item-${i}`}
								className={css.item}
							>
								<img src={`${IMAGES_PATH}${partner.logoSrc}`} alt="" />
							</a>
						))}
					</div>
				</div>
			</div>
		);
	};

	render() {
		return (
			<div id="group-assets">
				<ScrollAnimation animateOnce animateIn="fadeIn">
					{this.rednerAddsSection()}
				</ScrollAnimation>
			</div>
		);
	}
}
