import _ from 'lodash';

function isLocalStorageExists() {
	const test = 'test';
	try {
		localStorage.setItem(test, test);
		localStorage.removeItem(test);
		return true;
	} catch (e) {
		return false;
	}
}

const debug = _.get(process, 'env.DEBUG');

export default function setDebug() {
	if (isLocalStorageExists() && debug) {
		localStorage.setItem('debug', debug);
	}
}
