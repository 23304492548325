import appConfig from 'application-config';

const { IS_ADMIN } = process.env;
export const BACKEND_HOSTNAME = appConfig.domains.backend[IS_ADMIN ? 'adminHostname' : 'clientHostname'];

export const API_ENDPOINTS = {
	me: '/auth/me',
	checkIP: '/auth/checkIP',
	getSettings: '/settings',
	updateSettings: '/settings/update',

	getCategories: '/categories',
	updateCategory: '/categories/update',

	getItems: '/items',
	updateItem: '/items/update',
	updateItemsOrder: '/items/update/order',
	deleteItem: '/items/delete',

	checkSecurity: '/security',
};
