import * as React from 'react';
import PT from 'prop-types';
import classNames from 'classnames';

import * as css from './Text.scss';

const Text = ({ className, tag, children, type, ...props }) => {
	const T = tag;

	return (
		<T className={classNames(css.text, css[type], className)} {...props}>
			{children}
		</T>
	);
};

Text.DEFAULT = 'default';
Text.SECTION_TITLE = 'sectionTitle';
Text.REGULAR_30 = 'regular-30';
Text.BOLD_28 = 'bold-28';
Text.BOLD_30 = 'bold-30';
Text.SMALL = 'small';

Text.defaultProps = {
	className: '',
	tag: 'p',
	children: null,
	type: Text.DEFAULT,
};

Text.propTypes = {
	className: PT.string,
	tag: PT.string,
	children: PT.node,
	type: PT.oneOf([Text.DEFAULT, Text.SECTION_TITLE, Text.SMALL, Text.REGULAR_30, Text.BOLD_28, Text.BOLD_30]),
};

export default Text;
