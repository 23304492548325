import React from 'react';
import PT from 'prop-types';
import classNames from 'classnames';
import yitLogo from 'assets/client/imgs/footer/yit-footer.png';

import * as css from './Footer.scss';

const Footer = ({ className }) => {
	const yitLink = 'https://yit.co.il';
	const termsLink = 'https://z.ynet.co.il/short/content/2018/privacypolicy/terms.html';
	const privacyLink = 'https://z.ynet.co.il/short/content/2018/privacypolicy/policy.html';

	return (
		<footer className={classNames(css.footer, className)}>
			<div className={css.footerBottom}>
				<a href={yitLink} target="_blank" rel="noopener noreferrer" className={css.yitLogoLink}>
					<img src={yitLogo} alt="yit-logo" />
				</a>
				<a href={yitLink} target="_blank" rel="noopener noreferrer" className={css.textWithLine}>
					עיצוב, אפיון ופיתוח
				</a>
				<a href={privacyLink} target="_blank" rel="noopener noreferrer">
					<span className={css.textWithLine}>מדיניות פרטיות</span>
				</a>
				<a href={termsLink} target="_blank" rel="noopener noreferrer">
					<span>תנאי שימוש</span>
				</a>
			</div>
		</footer>
	);
};

Footer.defaultProps = {
	className: '',
};

Footer.propTypes = {
	className: PT.string,
};

export default Footer;
