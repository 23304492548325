/* eslint-disable max-len */
// first screen

const programmaticDepartment = {
	en: {
		title: 'Our Programmatic department',
		items: [
			{
				iconSrc: '/programmaticDepartment/icon1.png',
				text: `We specialize in recency activity, based on data and segmentation. We use assigned tools and optimized purchasing systems.`,
			},
			{
				iconSrc: '/programmaticDepartment/icon3.png',
				text: `We manage and sell different audiences directly or tailored <br/> especially for clients in several outlets.`,
			},
			{
				iconSrc: '/programmaticDepartment/icon2.png',
				text: `We also supply services and terminals on SAAS based platforms <br/> for clients (DMP 360 Display & Video, for example).`,
			},
		],
	},
	he: {
		title: 'המחלקה הפרוגרמטית',
		items: [
			{
				iconSrc: '/programmaticDepartment/icon1.png',
				text: `המחלקה הפרוגרמטית מתמחה בפעילות ריסנסי מבוססת דאטא וקהלים,
						<br/>פיתוח כלים ייעודיים, פעילות מכוונת תוצאות ושימוש במערכות קנייה ואופטימזציה חכמות.`,
			},
			{
				iconSrc: '/programmaticDepartment/icon3.png',
				text: ` מכירת רשימות קהלים דרך מערכת הטרמינל של IDX בהתאמה ללקוחות לשימוש
						<br/>באופן ישיר באתרי הקבוצה או באתרים חיצוניים.`,
			},
			{
				iconSrc: '/programmaticDepartment/icon2.png',
				text: `כמו כן אנחנו מספקים שירותי SAAS ויעוץ במספר מערכות קנייה פרוגרמטיות כגון
						<br/>DMP 360 Display & Video, ומסופי קנייה ישירה לשימוש לקוחות קצה.`,
			},
		],
	},
	ar: {
		title: 'قسم البرمجة​',
		items: [
			{
				iconSrc: '/programmaticDepartment/icon1.png',
				text: ` قسم البرمجة متخصص بنشاط متكرر,  يعتمد على معطيات وجمهور, تطوير أدوات
					مخصصة, نشاط موجه ونتائج واستخدام في أنظمة الشراء الأمثل والأكثر ذكاء.`,
			},
			{
				iconSrc: '/programmaticDepartment/icon3.png',
				text: `بيع سجلّات جمهور عن طريق أنظمة ترمينال IDX ملائمة للزبائن للاستعمال
					.بشكل مباشر في موقع المجموعة أو بموقع خارجية`,
			},
			{
				iconSrc: '/programmaticDepartment/icon2.png',
				text: ` كذلك, نزوّد بشكل مباشر خدمات SAAS واستشارة في عدد من برامج أنظمة الشراء
					من DMP 360 Display & Video, ومحطات شراء مباشرة لاستخدام زبائننا.`,
			},
		],
	},
};

export default programmaticDepartment;
