import { createStore, compose, applyMiddleware } from 'redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { createLogger } from 'redux-logger';

import { fetchMiddleware } from 'middlewares';
import { fetchModule } from 'modules';
import { IS_DEVEL } from 'src/routes/client/constants';

const history = createBrowserHistory();

function configureStoreDev(initialState, reducer) {
	const middlewares = [
		// thunk middleware can also accept an extra argument to be passed to each thunk action
		// https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
		// thunk.withExtraArgument(thunkExtraArguments),
		thunk,
		fetchMiddleware(fetchModule),
	];

	if (IS_DEVEL) {
		middlewares.push(
			// Redux middleware that spits an error on
			// you when you try to mutate your state either inside a dispatch
			// or between dispatches (this module might be the cause for some issues with react-redux-form):
			reduxImmutableStateInvariant(),
			// must come after thunks to avoid undefined action dispatches
			// Redux action logging
			createLogger({ collapsed: true, diff: true })
		);
	}

	// add support for Redux dev tools:
	const composeEnhancers = IS_DEVEL ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;
	const store = createStore(reducer, initialState, composeEnhancers(applyMiddleware(...middlewares)));

	if (module.hot) {
		// Enable Webpack hot module replacement for reducers
		module.hot.accept('./reducers', () => {
			const nextReducer = require('./reducers').default; // eslint-disable-line global-require
			store.replaceReducer(nextReducer);
		});
	}

	return store;
}

export { history };

// @todo create a production configure store function
export default configureStoreDev;
