import React from 'react';
import PT from 'prop-types';
import classNames from 'classnames';
import data from 'client/data';
import { IMAGES_PATH } from 'client/constants';

import * as css from './Header.scss';

const Logo = props => {
	const { className, activeLanguage } = props;
	const { general } = data;

	if (!activeLanguage) return null;
	return (
		<div className={classNames(css.logo, className)}>
			<img src={`${IMAGES_PATH}${general[activeLanguage].ynetLogo.img}`} alt="Ynet Logo" />
			<span>{general[activeLanguage].ynetLogo.text}</span>
		</div>
	);
};

Logo.defaultProps = {
	className: '',
	activeLanguage: '',
};

Logo.propTypes = {
	className: PT.string,
	activeLanguage: PT.string,
};

export default Logo;
