import { BACKEND_HOSTNAME } from 'common/constants';

class FetchModule {
	getResFrame = () => ({
		data: null,
		success: false,
		message: '',
	});

	processError = e => {
		const result = this.getResFrame();

		if (e.error) {
			result.message = e.error;
		} else if (e.message) {
			result.message = e.message;
		} else {
			result.message = 'something went wrong';
		}

		return result;
	};

	send = async (url, options) => {
		const targetUrl = `${BACKEND_HOSTNAME}${url}`;
		let result = this.getResFrame();
		let response;
		let jsonResp;

		try {
			response = await fetch(targetUrl, options);

			jsonResp = await response.json();

			if (response.status === 200 || response.status === 204) {
				result.success = true;
				result.data = jsonResp.data;
			} else {
				throw new Error(jsonResp.error || jsonResp.message);
			}
		} catch (e) {
			result = this.processError(e);
		}

		return result;
	};
}

export const fetchModule = new FetchModule();
