/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-curly-brace-presence */
import * as React from 'react';
import PT from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';

import { Responsive } from 'common/components';
import data from 'client/data';
import ScrollAnimation from 'react-animate-on-scroll';
import { IMAGES_PATH } from 'client/constants';

import * as css from './Statistic.scss';

export default class Statistic extends React.PureComponent {
	static propTypes = {
		className: PT.string,
		activeLanguage: PT.string,
	};

	static defaultProps = {
		className: '',
		activeLanguage: '',
	};

	renderNotes = notes => {
		return (
			<div className={css.notes}>
				<p>{notes}</p>
			</div>
		);
	};

	render() {
		const { className, activeLanguage } = this.props;
		const { statistic } = data;
		const isMobile = Responsive.isMatching(Responsive.MOBILE);
		if (!activeLanguage) return null;
		return (
			<div className={classNames(css.statistic, className)} id="users-numbers">
				<div className={css.content}>
					<ScrollAnimation animateOnce animateIn="fadeIn">
						<div className={css.top}>
							<h3>{statistic[activeLanguage].title}</h3>
						</div>
					</ScrollAnimation>
					<ScrollAnimation animateOnce animateIn="fadeIn">
						<div className={css.center}>
							{_.map(statistic[activeLanguage].items, (item, index) => (
								<div key={`item-${index}`} className={classNames(css.item, css[item.name])}>
									<div className={css.icon}>
										<img src={`${IMAGES_PATH}${item.icon}`} alt="" />
									</div>
									<div className={css.title}>
										{isMobile && item.titleMob !== undefined ? item.titleMob : item.title}
									</div>
									<div className={css.subTitle}>{item.subTitle}</div>
								</div>
							))}
						</div>
					</ScrollAnimation>
					<ScrollAnimation animateOnce animateIn="fadeIn">
						<div className={css.bottom}>
							<div className={css.icon}>
								<img src={`${IMAGES_PATH}${statistic[activeLanguage].bottomIcon}`} alt="" />
							</div>
							<div className={classNames(css.text, css.textBeforeNumber)}>
								<p>{statistic[activeLanguage].textBeforeNumber}</p>
							</div>
							<div className={css.number}>{statistic[activeLanguage].bottomNumber}</div>
							<div className={css.text}>
								<h4>{statistic[activeLanguage].bottomTitle}</h4>
								<p>{statistic[activeLanguage].bottomSubTitle}</p>
							</div>
						</div>
						{this.renderNotes(statistic[activeLanguage].notes)}
					</ScrollAnimation>
				</div>
			</div>
		);
	}
}
