/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/media-has-caption */
import * as React from 'react';
import PT from 'prop-types';
import classNames from 'classnames';

import { IMAGES_PATH } from 'client/constants';

import * as css from './ImageGallery.scss';

export default class GalleryItem extends React.PureComponent {
	static propTypes = {
		imageSrc: PT.string,
		subImageSrc: PT.string,
		animate: PT.bool,
	};

	static defaultProps = {
		imageSrc: '',
		subImageSrc: '',
		animate: false,
	};

	state = {};

	render() {
		const { imageSrc, subImageSrc, animate } = this.props;

		return (
			<div className={classNames(css.imageWrap, { [css.active]: animate })}>
				<img
					src={`${IMAGES_PATH}/intro/gallery/${subImageSrc}`}
					alt={`${imageSrc}`}
					className={classNames(css.image, css.under)}
				/>
				<img
					src={`${IMAGES_PATH}/intro/gallery/${imageSrc}`}
					alt={`${imageSrc}`}
					data-id={imageSrc}
					className={classNames(css.image, css.current)}
				/>
			</div>
		);
	}
}
