/* eslint-disable max-len */
const purchase = {
	he: {
		title: 'שיטות קנייה',
		app: {
			banner: {
				bannerImg: '/purchase/purchase_icon.png',
			},
			info: [
				{
					title: 'דיירקט (קנייה ישירה):',
					text: `פרסום בכל אתרי וערוצי הקבוצה (ניתן לעשות <span>exclude</span>) <br/> קמפיין ישיר: העלייה דרך <span>Ad Manager</span> <br/> הכולל <span>Brand Safety</span> בשילוב תכני ynet.`,
				},
				{
					title: '<span>PG:</span>',
					text: `פילוח קהלים וניהול חכם של קמפיינים כולל אפשרויות</br> <span>Sequence, Exclude, Frequency Mangement</span>`,
				},
			],
			subInfo: [
				{
					subTitle1: `1.`,
					subTitle2: `קהלי IDX:`,
					text: ` מערכת קנייה שבה ניתן לקנות וידאו מטרוגט קהלים המאופיינים <br/> לפי תוכן.`,
				},
				{
					subTitle1: `360.2`,
					subTitle2: `DV:`,
					text: `מערכת היוצרת נטוורק משולב של <span>ynet</span> ושל <span>Youtube.</span> מציעה ניהול נכון של תקציבים ו <span>-Reach</span> מקסימלי.<br/> מאפשרת קנייה מבוססת קהל <span>First and third party,</span> טרגוט מין וגיל ו <span>-Affinity.</span>  מאפשרת לנהל מסע לקוח ושילוב<br/> טרגוט לפי גוגל דאטה בעסקאות <span>PG.</span> `,
				},
			],
		},
	},
	en: {
		title: 'Purchase methods',
		app: {
			banner: {
				bannerImg: '/purchase/purchase_icon.png',
			},
			info: [
				{
					title: 'Direct purchase:',
					text: `Advertising on all websites and channels that belong to the group <br/> (exclude available) <br/> Direct campaign: uploaded through Ad Manager, including brand <br/> safety aligned with ynet's content.`,
				},
				{
					title: 'PG:',
					text: `segmented audience, smart campaign management including <br/> Sequence, Exclude, frequency management.`,
				},
			],
			subInfo: [
				{
					subTitle: `<span>1.</span> IDX Audience:`,
					text: `A purchase system that allows the customer to focus on audience <br/> and content based characteristics.`,
				},
				{
					subTitle: `<span>2.</span> DV<span>360</span>:`,
					text: `A system that creates an internal network that consists ynet and yotube. Offers intelligent <br/> budget management and maximum reach. Offers purchase based on audience, first and third <br/> party, sex and age targeting, Affinity. Offers managing the customer journey and Google data <br/> targeting through PG deals.`,
				},
			],
		},
	},
};

export default purchase;
