/* eslint-disable react/no-danger, indent, react/jsx-indent */
/* eslint-disable react/jsx-curly-brace-presence */
import * as React from 'react';
import PT from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import ScrollAnimation from 'react-animate-on-scroll';
import data from 'client/data';

import { IMAGES_PATH } from 'client/constants';

import * as css from './Purchase.scss';

const appInfoRef = React.createRef();

export default class Purchase extends React.PureComponent {
	static propTypes = {
		className: PT.string,
		activeLanguage: PT.string,
	};

	static defaultProps = {
		className: '',
		activeLanguage: '',
	};

	renderInfo = () => {
		const { activeLanguage } = this.props;
		if (!activeLanguage) return null;
		const { purchase } = data;
		const { info } = purchase[activeLanguage].app;

		return (
			<div className={css.appInfo} ref={appInfoRef}>
				<div className={css.installAppInfo}>
					{_.map(info, (item, i) => (
						<div key={`inst-item-${i}`} className={css.item}>
							<p className={css.itemTitle} dangerouslySetInnerHTML={{ __html: item.title }} />
							<p className={css.itemBottomText} dangerouslySetInnerHTML={{ __html: item.text }} />
						</div>
					))}
				</div>
			</div>
		);
	};

	renderSubInfo = () => {
		const { activeLanguage } = this.props;
		if (!activeLanguage) return null;
		const { purchase } = data;
		const { subInfo } = purchase[activeLanguage].app;

		return (
			<div className={css.appInfo} ref={appInfoRef}>
				<div className={css.installAppInfo}>
					{_.map(subInfo, (item, i) => (
						<div key={`inst-item-${i}`} className={css.subItem}>
							{item.subTitle1 ? (
								<div className={css.subItemTitleGroup}>
									<p
										className={css.subItemTitle1}
										dangerouslySetInnerHTML={{ __html: item.subTitle1 }}
									/>
									<p
										className={css.subItemTitle2}
										dangerouslySetInnerHTML={{ __html: item.subTitle2 }}
									/>
								</div>
							) : (
								<p className={css.subItemTitle} dangerouslySetInnerHTML={{ __html: item.subTitle }} />
							)}
							<p className={css.subItemBottomText} dangerouslySetInnerHTML={{ __html: item.text }} />
						</div>
					))}
				</div>
			</div>
		);
	};

	renderBanner = () => {
		const { activeLanguage } = this.props;
		if (!activeLanguage) return null;
		const { purchase } = data;
		const { banner } = purchase[activeLanguage].app;
		return (
			<div className={css.banner}>
				<img src={`${IMAGES_PATH}${banner.bannerImg}`} alt="banner" className={css.bannerImg} />
			</div>
		);
	};

	renderAppSection = () => {
		const { activeLanguage } = this.props;
		if (!activeLanguage) return null;
		const { purchase } = data;
		return (
			<div className={css.app}>
				<div className={css.content}>
					<div className={css.title}>
						<h3>{purchase[activeLanguage].title}</h3>
					</div>
					<div className={css.appContent}>
						<div className={css.info}>
							{this.renderInfo()}
							{this.renderBanner()}
						</div>
						{this.renderSubInfo()}
					</div>
				</div>
			</div>
		);
	};

	render() {
		const { className } = this.props;

		return (
			<div id="app-rank" className={classNames(css.purchase, className)}>
				<ScrollAnimation animateOnce animateIn="fadeIn">
					{this.renderAppSection()}
				</ScrollAnimation>
			</div>
		);
	}
}
