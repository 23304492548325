/* eslint-disable react/no-danger, indent, react/jsx-indent */
/* eslint-disable react/jsx-curly-brace-presence */
import * as React from 'react';
import PT from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import ScrollAnimation from 'react-animate-on-scroll';
import data from 'client/data';

import { IMAGES_PATH } from 'client/constants';

import * as css from './Cooperation.scss';

export default class Cooperation extends React.PureComponent {
	static propTypes = {
		className: PT.string,
		activeLanguage: PT.string,
	};

	static defaultProps = {
		className: '',
		activeLanguage: '',
	};

	rednerCooperationSection = () => {
		const { activeLanguage } = this.props;
		const { cooperation } = data;
		const { logoSrc, items } = cooperation;
		if (!activeLanguage) return null;
		return (
			<div className={classNames(css.content)}>
				<div className={css.cooperationInfo}>
					<div className={css.cooperationInfoText}>
						<h4>{cooperation[activeLanguage].title}</h4>
						<p dangerouslySetInnerHTML={{ __html: cooperation[activeLanguage].text }}></p>
					</div>
					<div className={css.cooperationInfoLogo}>
						<img src={`${IMAGES_PATH}${logoSrc}`} alt="logo" />
					</div>
				</div>

				<div className={css.cooperationItems}>
					{_.map(items, (item, i) => (
						<div key={`cooperation-item-${i}`} className={css.item}>
							<a href={item.src} target="_blank" rel="noopener noreferrer">
								<div className={css.logo}>
									<img src={`${IMAGES_PATH}${item.logoSrc}`} alt="logo" />
								</div>
								<div className={css.frame}>
									<img src={`${IMAGES_PATH}${item.macSrc}`} alt="macbook" />
								</div>
							</a>
						</div>
					))}
				</div>
			</div>
		);
	};

	render() {
		const { className } = this.props;

		return (
			<div id="strategic-collaborations" className={classNames(css.cooperation, className)}>
				<ScrollAnimation animateOnce animateIn="fadeIn">
					{this.rednerCooperationSection()}
				</ScrollAnimation>
			</div>
		);
	}
}
