/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-curly-brace-presence */
import * as React from 'react';
import PT from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';

import data from 'client/data';
import ScrollAnimation from 'react-animate-on-scroll';
import { IMAGES_PATH } from 'client/constants';

import * as css from './StatisticVideo.scss';

export default class StatisticVideo extends React.PureComponent {
	static propTypes = {
		className: PT.string,
		activeLanguage: PT.string,
	};

	static defaultProps = {
		className: '',
		activeLanguage: '',
	};

	render() {
		const { className, activeLanguage } = this.props;
		const { statisticVideo } = data;
		if (!activeLanguage) return null;
		return (
			<div className={classNames(css.statisticVideo, className)} id="users-numbers">
				<div className={css.content}>
					<ScrollAnimation animateOnce animateIn="fadeIn">
						<div className={css.top}>
							<h3>{statisticVideo[activeLanguage].title}</h3>
						</div>
					</ScrollAnimation>
					<ScrollAnimation animateOnce animateIn="fadeIn">
						<div className={css.center}>
							{_.map(statisticVideo[activeLanguage].items, (item, index) => (
								<div key={`item-${index}`} className={classNames(css.item, css[item.name])}>
									<div className={css.icon}>
										<img src={`${IMAGES_PATH}${item.icon}`} alt="" />
									</div>
									{item.title1 && (
										<div
											className={css.title1}
											dangerouslySetInnerHTML={{ __html: item.title1 }}
										></div>
									)}
									<div className={css.title} dangerouslySetInnerHTML={{ __html: item.title }}></div>
									<div
										className={css.subTitle}
										dangerouslySetInnerHTML={{ __html: item.subTitle }}
									></div>
								</div>
							))}
							<div className={css.dots}></div>
						</div>
					</ScrollAnimation>
				</div>
			</div>
		);
	}
}
