import _ from 'lodash';
import debug from 'debug';

const log = debug('utils:translate');

/**
 * @desc template settings for translaton
 * @see {@link https://lodash.com/docs/4.17.10#templateSettings}
 */
const translateTemplateSettings = {
	interpolate: /{{([\s\S]+?)}}/g,
};

/**
 * translate - translate strings, optionally with parameters
 * @see {@link https://lodash.com/docs/4.17.10#template}
 *
 * @param  {string} path = '' path in translations object
 * @param  {object} options   template data (optional)
 * @return {string} parsed template
 */
function translate(path = '', options) {
	log('[translate] arguments', path, options);

	const translations = window.__translations || {};
	let result = _.get(translations, path, window.__translations ? path : ' ');
	if (_.isObject(options)) {
		const compiled = _.template(result, translateTemplateSettings);
		result = compiled(options);
	}

	log('[translate] result', result);

	return result;
}

/**
 * @class Translator
 * saves locale for translation context
 */
class Translator {
	constructor(locale = 'he') {
		this.locale = locale;
	}

	set locale(locale) {
		this._locale = locale;
	}

	get locale() {
		return this._locale;
	}

	translate = (path, options) => {
		log('Translator@translate locale', this.locale);
		// eslint-disable-next-line no-param-reassign
		path = `${this.locale}.${path}`;
		return translate(path, options);
	};
}

export const translator = new Translator();
export default translator.translate;
