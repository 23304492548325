/* eslint-disable max-len */
// first screen

const cooperation = {
	en: {
		title: 'Our Strategic collaborations department',
		text: `We specialize in creating and promoting ideas for big client moves, using data and analyzing marketing and sales goals.<br/>We offer tailored content creation, building a realistic timeline and producing insights and reports.
		`,
	},
	he: {
		title: 'מחלקת שיתופי הפעולה האסטרטגים',
		text: `<p>מחלקת שיתופי הפעולה שלנו מתמחה בפיתוח רעיונות אסטרטגיים לפרסום,
            <br/>באמצעות איסוף נתונים וניתוח מטרות מסחריות ושיווקיות.
            <br/>אנו מציעים שירותי יצירת תוכן מותאם ללקוח, בניית לוח זמנים והפקת דו"חות ומדדים.</p>`,
	},
	ar: {
		title: 'قسم التعاونات الاستراتيجية​',
		text: `قسم التعاون الخاص بنا متخصص في تطوير أفكار استراتيجية
			للإعلان, بواسطة جمع معطيات وتحليل أهداف تجارية وتسويقية.
			نقدم خدمات إنتاج محتوى تتم ملائمته للزبون, بناء جدول زمني,
			وتقديم تقارير ومؤشرات.`,
	},
	logoSrc: '/cooperation/main-logo.png',
	items: [
		{
			src: 'https://www.ynet.co.il/home/0,7340,L-11906,00.html',
			logoSrc: '/cooperation/items-logo4.png',
			macSrc: '/cooperation/tnuva.png',
		},
		{
			src: 'https://www.ynet.co.il/home/0,7340,L-13390,00.html',
			logoSrc: '/cooperation/items-logo3.png',
			macSrc: '/cooperation/telma.png',
		},
		{
			src: 'https://www.ynet.co.il/home/0,7340,L-11189,00.html',
			logoSrc: '/cooperation/items-logo2.png',
			macSrc: '/cooperation/osem.png',
		},
		{
			src: 'https://www.ynet.co.il/economy/zoomin',
			logoSrc: '/cooperation/items-logo1.png',
			macSrc: '/cooperation/poalim.png',
		},
	],
};

export default cooperation;
