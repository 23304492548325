import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Route, Switch } from 'react-router';

import { analytics } from 'common/utils/analytics';
import { analyticsLinksData } from 'client/data/menu';
import _ from 'lodash';
import PT from 'prop-types';
import classNames from 'classnames';

import { Header, Footer } from 'client/components/common';
import Intro from 'client/components/Screens/Intro';
import Statistic from 'client/components/Screens/Statistic';
import Graphics from 'client/components/Screens/Graphics';
import Advertising from 'client/components/Screens/Advertising';
import Partners from 'client/components/Screens/Partners';
import Cooperation from 'client/components/Screens/Cooperation';
import ProgrammaticDepartment from 'client/components/Screens/ProgrammaticDepartment';
import AdvertasingArticles from 'client/components/Screens/advertasingArticles';
import InStreamPost from 'client/components/Screens/InStream';
import StatisticVideo from 'client/components/Screens/StatisticVideo';
import SystemPartition from 'client/components/Screens/SystemPartition';

import { LANG_ROUTES, LANGUAGES } from 'client/constants';

import * as css from './App.scss';
import Consumption from './components/Screens/Consumption';
import Purchase from './components/Screens/Purchase';

const scr = (scroll, id) => {
	const element = document.querySelectorAll(id)[0];
	const halfHeight = window.innerHeight / 3;
	return scroll + halfHeight >= element.offsetTop && scroll < element.offsetTop + element.offsetHeight - halfHeight;
};

const ALL_LANGUAGES = ['he', 'en', 'ar'];

export default class App extends Component {
	static propTypes = {
		history: PT.shape({
			location: PT.shape({
				pathname: PT.string,
			}),
		}).isRequired,
	};

	state = {
		isTop: false,
		isIntro: false,
		isVideo: false,
		isUsersNumbers: false,
		isAppRank: false,
		isLeadingProducts: false,
		isStreamAd: false,
		isPromotionalArticles: false,
		isSystemPartition: false,
		isProgrammaticDepartment: false,
		isStrategicCollaborations: false,
		isGroupAssets: false,
		languages: ['en', 'ar'],
		activeLanguage: 'he',
	};

	componentDidMount() {
		this.removeRootPreloader();

		document.querySelector('html').classList.add(css.appPage);

		window.addEventListener('scroll', this.handleScroll);

		if (this.props.history.location.pathname.length) {
			const lang = this.props.history.location.pathname.replace('/', '');
			const currentLang = lang.length > 0 ? lang : 'he';

			this.setState({ activeLanguage: currentLang, languages: _.without(ALL_LANGUAGES, currentLang) });
			this.setLangAttribute(currentLang);
		}
	}

	componentWillUnmount() {
		document.querySelector('html').classList.remove(css.appPage);
		window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll = () => {
		const scroll = window.pageYOffset || document.documentElement.scrollTop;
		const topSection = scr(scroll, '#top');
		const intro = scr(scroll, '#intro');
		const videoId = scr(scroll, '#video');
		const {
			isTop,
			isIntro,
			isVideo,
			isUsersNumbers,
			isAppRank,
			isLeadingProducts,
			isStreamAd,
			isPromotionalArticles,
			isProgrammaticDepartment,
			isStrategicCollaborations,
			isGroupAssets,
			isSystemPartition,
		} = this.state;

		if (this.state.activeLanguage !== 'ar') {
			const usersNumbers = scr(scroll, '#users-numbers');
			if (!isUsersNumbers && usersNumbers) {
				this.setState({
					isTop: false,
					isIntro: false,
					isVideo: false,
					isUsersNumbers: true,
					isAppRank: false,
					isLeadingProducts: false,
					isStreamAd: false,
					isPromotionalArticles: false,
					isSystemPartition: false,
					isProgrammaticDepartment: false,
					isStrategicCollaborations: false,
					isGroupAssets: false,
				});
				analytics.onHashChange(analyticsLinksData[3].id, analyticsLinksData[3].gaTitle);
			}
		}

		if (this.state.activeLanguage === 'he') {
			const systemPartition = scr(scroll, '#system-partition');
			if (!isSystemPartition && systemPartition) {
				this.setState({
					isTop: false,
					isIntro: false,
					isVideo: false,
					isUsersNumbers: false,
					isAppRank: false,
					isLeadingProducts: false,
					isStreamAd: false,
					isPromotionalArticles: false,
					isSystemPartition: true,
					isProgrammaticDepartment: false,
					isStrategicCollaborations: false,
					isGroupAssets: false,
				});
				analytics.onHashChange(analyticsLinksData[8].id, analyticsLinksData[8].gaTitle);
			}
		}
		const appRank = scr(scroll, '#app-rank');
		const leadingProducts = scr(scroll, '#leading-products');
		const streamAd = scr(scroll, '#in-stream-ad');
		const promotionalArticles = scr(scroll, '#promotional-articles');
		const programmaticDepartment = scr(scroll, '#programmatic-department');
		const strategicCollaborations = scr(scroll, '#strategic-collaborations');
		const groupAssets = scr(scroll, '#group-assets');

		if (!isTop && topSection) {
			this.setState({
				isTop: true,
				isIntro: false,
				isVideo: false,
				isUsersNumbers: false,
				isAppRank: false,
				isLeadingProducts: false,
				isStreamAd: false,
				isPromotionalArticles: false,
				isSystemPartition: false,
				isProgrammaticDepartment: false,
				isStrategicCollaborations: false,
				isGroupAssets: false,
			});
			analytics.onHashChange('/', analyticsLinksData[0].gaTitle);
			// console.info('TOP');
		}

		if (!isIntro && intro) {
			this.setState({
				isTop: false,
				isIntro: true,
				isVideo: false,
				isUsersNumbers: false,
				isAppRank: false,
				isLeadingProducts: false,
				isStreamAd: false,
				isPromotionalArticles: false,
				isSystemPartition: false,
				isProgrammaticDepartment: false,
				isStrategicCollaborations: false,
				isGroupAssets: false,
			});
			analytics.onHashChange(analyticsLinksData[1].id, analyticsLinksData[1].gaTitle);
			// console.info('INTRO');
		}

		if (!isVideo && videoId) {
			this.setState({
				isTop: false,
				isIntro: false,
				isVideo: true,
				isUsersNumbers: false,
				isAppRank: false,
				isLeadingProducts: false,
				isStreamAd: false,
				isPromotionalArticles: false,
				isSystemPartition: false,
				isProgrammaticDepartment: false,
				isStrategicCollaborations: false,
				isGroupAssets: false,
			});
			analytics.onHashChange(analyticsLinksData[2].id, analyticsLinksData[2].gaTitle);
			// console.info('VIDEO');
		}

		if (!isAppRank && appRank) {
			this.setState({
				isTop: false,
				isIntro: false,
				isVideo: false,
				isUsersNumbers: false,
				isAppRank: true,
				isLeadingProducts: false,
				isStreamAd: false,
				isPromotionalArticles: false,
				isSystemPartition: false,
				isProgrammaticDepartment: false,
				isStrategicCollaborations: false,
				isGroupAssets: false,
			});
			analytics.onHashChange(analyticsLinksData[4].id, analyticsLinksData[4].gaTitle);
			// console.info('APP RANK');
		}

		if (!isLeadingProducts && leadingProducts) {
			this.setState({
				isTop: false,
				isIntro: false,
				isVideo: false,
				isUsersNumbers: false,
				isAppRank: false,
				isLeadingProducts: true,
				isStreamAd: false,
				isPromotionalArticles: false,
				isSystemPartition: false,
				isProgrammaticDepartment: false,
				isStrategicCollaborations: false,
				isGroupAssets: false,
			});
			analytics.onHashChange(analyticsLinksData[5].id, analyticsLinksData[5].gaTitle);
			// console.info('Liding Products');
		}

		if (!isStreamAd && streamAd) {
			this.setState({
				isTop: false,
				isIntro: false,
				isVideo: false,
				isUsersNumbers: false,
				isAppRank: false,
				isLeadingProducts: false,
				isStreamAd: true,
				isPromotionalArticles: false,
				isSystemPartition: false,
				isProgrammaticDepartment: false,
				isStrategicCollaborations: false,
				isGroupAssets: false,
			});
			analytics.onHashChange(analyticsLinksData[6].id, analyticsLinksData[6].gaTitle);
			// console.info('STREAM AD');
		}

		if (!isPromotionalArticles && promotionalArticles) {
			this.setState({
				isTop: false,
				isIntro: false,
				isVideo: false,
				isUsersNumbers: false,
				isAppRank: false,
				isLeadingProducts: false,
				isStreamAd: false,
				isPromotionalArticles: true,
				isSystemPartition: false,
				isProgrammaticDepartment: false,
				isStrategicCollaborations: false,
				isGroupAssets: false,
			});
			analytics.onHashChange(analyticsLinksData[7].id, analyticsLinksData[7].gaTitle);
			// console.info('Promotional Articles');
		}

		if (!isProgrammaticDepartment && programmaticDepartment) {
			this.setState({
				isTop: false,
				isIntro: false,
				isVideo: false,
				isUsersNumbers: false,
				isAppRank: false,
				isLeadingProducts: false,
				isStreamAd: false,
				isPromotionalArticles: false,
				isSystemPartition: false,
				isProgrammaticDepartment: true,
				isStrategicCollaborations: false,
				isGroupAssets: false,
			});
			analytics.onHashChange(analyticsLinksData[9].id, analyticsLinksData[9].gaTitle);
			// console.info('Programmatic Department');
		}

		if (!isStrategicCollaborations && strategicCollaborations) {
			this.setState({
				isTop: false,
				isIntro: false,
				isVideo: false,
				isUsersNumbers: false,
				isAppRank: false,
				isLeadingProducts: false,
				isStreamAd: false,
				isPromotionalArticles: false,
				isSystemPartition: false,
				isProgrammaticDepartment: false,
				isStrategicCollaborations: true,
				isGroupAssets: false,
			});
			analytics.onHashChange(analyticsLinksData[10].id, analyticsLinksData[10].gaTitle);
			// console.info('Strategic Collaborations');
		}

		if (!isGroupAssets && groupAssets) {
			this.setState({
				isTop: false,
				isIntro: false,
				isVideo: false,
				isUsersNumbers: false,
				isAppRank: false,
				isLeadingProducts: false,
				isStreamAd: false,
				isPromotionalArticles: false,
				isSystemPartition: false,
				isProgrammaticDepartment: false,
				isStrategicCollaborations: false,
				isGroupAssets: true,
			});
			analytics.onHashChange(analyticsLinksData[11].id, analyticsLinksData[11].gaTitle);
			// console.info('Group Assets');
		}
	};

	removeRootPreloader = () => {
		const preloadingScreen = document.querySelector('.preloading-screen');

		if (preloadingScreen) {
			preloadingScreen.addEventListener('transitionend', e => {
				if (e.target === preloadingScreen) {
					document.body.removeChild(preloadingScreen);
				}
			});
			preloadingScreen.style.opacity = 0;
		}
	};

	langClickListener = e => {
		const { lang } = e.currentTarget.dataset;

		this.setState(
			prevState => {
				const { activeLanguage } = prevState;
				if (lang === activeLanguage) return null;
				const notActive = _.without(ALL_LANGUAGES, lang);

				return {
					languages: notActive,
					activeLanguage: lang,
				};
			},
			() => {
				this.setLangAttribute(lang);
			}
		);
	};

	setLangAttribute = lang => {
		const htmlElement = document.querySelector('html');
		htmlElement.setAttribute('lang', lang);

		if (lang === LANG_ROUTES.EN) {
			htmlElement.setAttribute('dir', 'rtl');
		} else {
			htmlElement.setAttribute('dir', 'ltr');
		}
	};

	renderPage = activeLanguage => {
		return (
			<>
				<Intro activeLanguage={activeLanguage} />
				<Statistic activeLanguage={activeLanguage} />
				{activeLanguage !== 'ar' && <StatisticVideo activeLanguage={activeLanguage} />}
				<Graphics activeLanguage={activeLanguage} />
				<Advertising activeLanguage={activeLanguage} />
				{activeLanguage !== 'ar' && <Consumption activeLanguage={activeLanguage} />}
				<InStreamPost activeLanguage={activeLanguage} />
				{activeLanguage !== 'ar' && <Purchase activeLanguage={activeLanguage} />}
				<AdvertasingArticles activeLanguage={activeLanguage} />
				{activeLanguage === 'he' && <SystemPartition activeLanguage={activeLanguage} />}
				<ProgrammaticDepartment activeLanguage={activeLanguage} />
				<Cooperation activeLanguage={activeLanguage} />
				<Partners activeLanguage={activeLanguage} />
			</>
		);
	};

	renderRoute = props => {
		switch (props.match.path) {
			case LANG_ROUTES.HE:
				return this.renderPage(LANGUAGES.HE);

			case LANG_ROUTES.EN:
				return this.renderPage(LANGUAGES.EN);

			case LANG_ROUTES.AR:
				return this.renderPage(LANGUAGES.AR);

			default:
				return null;
		}
	};

	render() {
		const { languages, activeLanguage } = this.state;

		return (
			<BrowserRouter>
				<div className={classNames(css.app, activeLanguage === LANGUAGES.EN && css.eng)}>
					<Header
						languages={languages}
						langClickListener={this.langClickListener}
						activeLanguage={activeLanguage}
					/>
					<Switch>
						<Route path={LANG_ROUTES.HE} exact render={this.renderRoute} />
						<Route path={LANG_ROUTES.EN} exact render={this.renderRoute} />
						<Route path={LANG_ROUTES.AR} exact render={this.renderRoute} />
					</Switch>
					<Footer activeLanguage={activeLanguage} />
				</div>
			</BrowserRouter>
		);
	}
}
