/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/media-has-caption */
import * as React from 'react';
import PT from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

import data from 'client/data';
import { Responsive } from 'common/components';
import GalleryItem from './GalleryItem';

import * as css from './ImageGallery.scss';

const getRandomArray = (arr, lengs) => {
	return _.shuffle(_.sampleSize(arr, lengs));
};

export default class ImageGallery extends React.PureComponent {
	static propTypes = {
		className: PT.string,
	};

	static defaultProps = {
		className: '',
	};

	state = {
		images: [],
		animatedArr: ['1.jpg', '3.jpg', '4.jpg'],
	};

	componentDidMount() {
		const isDesktop = Responsive.isMatching(Responsive.DESKTOP);

		if (isDesktop) {
			this.setState({
				images: getRandomArray(data.intro.gallery, 18),
			});
		} else {
			this.setState({
				images: getRandomArray(data.intro.gallery, 15),
			});
		}

		setInterval(() => {
			const { images } = this.state;

			this.setState({
				animatedArr: getRandomArray(images, 3),
			});
		}, 3500);
	}

	renderGallery = () => {
		const { intro } = data;
		const { images, animatedArr } = this.state;

		return _.map(images, (image, index) => {
			const subImageSrc = intro.unActiveGallery[index % 14];
			const animate = animatedArr.includes(image);

			return <GalleryItem imageSrc={image} subImageSrc={subImageSrc} animate={animate} key={`image-${image}`} />;
		});
	};

	render() {
		const { className } = this.props;

		return <div className={classNames(className, css.gallery)}>{this.renderGallery()}</div>;
	}
}
