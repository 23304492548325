import React, { createRef } from 'react';
import PT from 'prop-types';
import classNames from 'classnames';

import Responsive from '../Responsive';
import ScrollBar from './ScrollBar';

import * as css from './Scrollable.scss';

const Scrollable = ({ className, children }) => {
	const contentWrapRef = createRef();
	const contentRef = createRef();

	return (
		<div className={classNames(css.scrollable, className)} ref={contentWrapRef}>
			<div className={css.scrollableContent} ref={contentRef}>
				{children}
			</div>
			<Responsive query={Responsive.DESKTOP}>
				<ScrollBar contentWrapRef={contentWrapRef} contentRef={contentRef} />
			</Responsive>
		</div>
	);
};

Scrollable.defaultProps = {
	className: '',
	children: null,
};

Scrollable.propTypes = {
	className: PT.string,
	children: PT.node,
};

export default Scrollable;
