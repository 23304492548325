import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PT from 'prop-types';
import classNames from 'classnames';
import { IMAGES_PATH, LANGUAGES } from 'client/constants';
import Menu from '../Menu';
import Logo from './Logo';

import * as css from './Header.scss';

const Header = props => {
	const { className, languages, langClickListener, activeLanguage } = props;
	const [openMenu, setOpenMenu] = useState(false);
	const handleBurgerClick = () => {
		setOpenMenu(true);
	};

	const handleMenuClick = () => {
		setOpenMenu(false);
	};

	return (
		<div className={classNames(css.header, className)}>
			<div className={css.wrapMenu}>
				<Menu active={openMenu} onClick={handleMenuClick} activeLanguage={activeLanguage} />
			</div>
			<div className={css.headerContent}>
				<div className={css.additionalIcons}>
					<button
						type="button"
						className={classNames(css.burgerMenuIcon /* bg ? css.black : css.white */)}
						onClick={handleBurgerClick}
					>
						<img src={`${IMAGES_PATH}/burger-icon.png`} alt="burger" />
					</button>
					<div className={css.langs}>
						<span className={css.activeLang}>{activeLanguage}</span>
						{languages.length > 0 && (
							<div className={css.list}>
								{languages.map(i => {
									const route = i === LANGUAGES.HE ? '/' : `/${i}`;

									return (
										<Link to={route} key={i}>
											<i data-lang={i} onClick={langClickListener}>
												{i}
											</i>
										</Link>
									);
								})}
							</div>
						)}
					</div>
				</div>

				<Logo activeLanguage={activeLanguage} />
			</div>
		</div>
	);
};

Header.defaultProps = {
	className: '',
	languages: [],
	langClickListener: () => {},
	activeLanguage: '',
};

Header.propTypes = {
	className: PT.string,
	languages: PT.arrayOf(PT.string),
	langClickListener: PT.func,
	activeLanguage: PT.string,
};

export default Header;
