/* eslint-disable react/no-danger, indent, react/jsx-indent, react/jsx-curly-brace-presence */
import * as React from 'react';
import PT from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import ScrollAnimation from 'react-animate-on-scroll';
import data from 'client/data';

import { IMAGES_PATH } from 'client/constants';

import * as css from './AdvertisingArticles.scss';

export default class AdvertisingArticles extends React.PureComponent {
	static propTypes = {
		className: PT.string,
		activeLanguage: PT.string,
	};

	static defaultProps = {
		className: '',
		activeLanguage: '',
	};

	rednerAdvertisingArticles = () => {
		const { activeLanguage } = this.props;
		const { advertasingArticles } = data;
		const { items } = advertasingArticles;
		if (!activeLanguage) return null;
		return (
			<div className={css.content}>
				<div className={css.info}>
					<h4 className={css.title}>{advertasingArticles[activeLanguage].title}</h4>
					{/* <p className={css.text}>{advertasingArticles[activeLanguage].text}</p> */}
					<p
						className={css.text}
						dangerouslySetInnerHTML={{ __html: advertasingArticles[activeLanguage].text }}
					/>
				</div>
				<div className={css.items}>
					{_.map(items, (item, i) => (
						<a
							href={item.link}
							target="_blank"
							rel="noopener noreferrer"
							key={`advertising-articles-item-${i}`}
							className={css.item}
						>
							<div className={css.itemImage}>
								<img src={`${IMAGES_PATH}${item.imageSrc}`} alt="article" />
							</div>
							<div className={css.itemText}>
								<p>{item.text}</p>
							</div>
							<div className={css.itemBottominfo}>
								<span>לעמוד הכתבה</span>
								<img className={css.itemLogo} src={`${IMAGES_PATH}${item.logoSrc}`} alt="logo" />
							</div>
						</a>
					))}
				</div>
			</div>
		);
	};

	render() {
		const { className } = this.props;

		return (
			<div id="promotional-articles" className={classNames(css.advertisingArticles, className)}>
				<ScrollAnimation animateOnc animateIn="fadeIn">
					{this.rednerAdvertisingArticles()}
				</ScrollAnimation>
			</div>
		);
	}
}
