/* eslint-disable react/no-danger, indent, react/jsx-indent */
/* eslint-disable react/jsx-curly-brace-presence */
import * as React from 'react';
import PT from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';

import ScrollAnimation from 'react-animate-on-scroll';
import data from 'client/data';
import { IMAGES_PATH, VIDEOS_PATH } from 'client/constants';

import { Responsive } from 'common/components';
import * as css from './InStreamPost.scss';

const videoRef = React.createRef();

export default class InStreamPost extends React.PureComponent {
	static propTypes = {
		className: PT.string,
		activeLanguage: PT.string,
	};

	static defaultProps = {
		className: '',
		activeLanguage: '',
	};

	state = {
		activeTab: 1,
	};

	componentDidMount() {
		const video = videoRef.current;
		if (Responsive.isMatching(Responsive.MOBILE)) {
			document.addEventListener('scroll', this.documentHandleClick);
			video.pause();
		} else {
			video.play();
		}
	}

	documentHandleClick = e => {
		const video = videoRef.current;
		const { y, height } = video.getBoundingClientRect();
		if (y <= height) {
			video.play();
		}
	};

	tab1 = (title, text) => {
		return (
			<div className={css.tab}>
				<h4 dangerouslySetInnerHTML={{ __html: title }}></h4>
				<p dangerouslySetInnerHTML={{ __html: text }}></p>
			</div>
		);
	};

	tab2 = (title, text) => {
		return (
			<div className={css.tab}>
				<h4 dangerouslySetInnerHTML={{ __html: title }}></h4>
				<p dangerouslySetInnerHTML={{ __html: text }}></p>
			</div>
		);
	};

	tab3 = (text, items) => {
		return (
			<div className={classNames(css.tab, css.tabLast)}>
				<p dangerouslySetInnerHTML={{ __html: text }}></p>
				<div className={css.items}>
					{_.map(items, (item, i) => {
						return (
							<div key={`tab-info-item-${i}`} className={css.item}>
								<div className={css.image}>
									<img src={`${IMAGES_PATH}${item.iconSrc}`} alt="icon" />
								</div>
								<p dangerouslySetInnerHTML={{ __html: item.text }}></p>
							</div>
						);
					})}
				</div>
			</div>
		);
	};

	handleClick = e => {
		e.preventDefault();
		this.setState(
			{
				activeTab: +e.target.getAttribute('data-name'),
			},
			() => {
				const $anchor = document.getElementsByClassName('tabs-info')[0];
				const offsetTop = $anchor.getBoundingClientRect().top + window.pageYOffset;
				if (Responsive.isMatching(Responsive.MOBILE)) {
					setTimeout(() => {
						window.scroll({
							// 60 - header height
							top: offsetTop - 60,
							behavior: 'smooth',
						});
					}, 200);
				}
			}
		);
	};

	rednerInStreamPost = () => {
		const { activeLanguage } = this.props;
		const { inStreamPost } = data;
		const { frameImageSrc, frameVideoSrc } = inStreamPost;
		const { activeTab } = this.state;
		if (!activeLanguage) return null;
		return (
			<div className={css.content}>
				<div className={css.info}>
					<h3
						className={css.title}
						dangerouslySetInnerHTML={{ __html: inStreamPost[activeLanguage].title }}
					></h3>
					<ul className={css.tabsControlls}>
						{_.map(inStreamPost[activeLanguage].tabsNames, (item, i) => {
							if (i + 1 === activeTab) {
								return (
									<li className={css.activeTab} key={`tab-control-item-${i}`}>
										<a href="#" data-name={i + 1} onClick={this.handleClick}>
											{item.name}
										</a>
									</li>
								);
							}
							return (
								<li key={`tab-control-item-${i}`}>
									<a href="#" data-name={i + 1} onClick={this.handleClick}>
										{item.name}
									</a>
								</li>
							);
						})}
					</ul>

					<div className="tabs-info">
						{activeTab === 1 &&
							this.tab1(
								inStreamPost[activeLanguage].tabsContent[0].title,
								inStreamPost[activeLanguage].tabsContent[0].text
							)}
						{activeTab === 2 &&
							this.tab2(
								inStreamPost[activeLanguage].tabsContent[1].title,
								inStreamPost[activeLanguage].tabsContent[1].text
							)}
						{activeTab === 3 &&
							this.tab3(
								inStreamPost[activeLanguage].tabsContent[2].text,
								inStreamPost[activeLanguage].tabsContent[2].items
							)}
					</div>
				</div>
				<div className={css.frameWarpper}>
					<video
						muted
						autoPlay
						loop
						playsInline
						src={`${VIDEOS_PATH}${frameVideoSrc}`}
						className={css.video}
						ref={videoRef}
					/>
					<img src={`${IMAGES_PATH}${frameImageSrc}`} alt="phone" />
				</div>
			</div>
		);
	};

	render() {
		const { className } = this.props;

		return (
			<div id="in-stream-ad" className={classNames(css.inStreamPost, className)}>
				<ScrollAnimation animateOnce animateIn="fadeIn">
					{this.rednerInStreamPost()}
				</ScrollAnimation>
			</div>
		);
	}
}
