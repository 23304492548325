/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable jsx-a11y/media-has-caption */
import * as React from 'react';
import PT from 'prop-types';
import classNames from 'classnames';

import { analytics } from 'common/utils/analytics';
// import { analyticsLinksData } from 'client/data/menu';

import { bgImage } from 'common/utils';
import { Responsive } from 'common/components';
import data from 'client/data';
import { ImageGallery } from 'client/components/common';
import { IMAGES_PATH } from 'client/constants';
import ScrollAnimation from 'react-animate-on-scroll';
import logo from 'assets/client/imgs/logo-ynet.png';
import videoPreviewDesktop from 'assets/client/imgs/intro/video-preview-desktop.jpg';
import videoPreviewMobile from 'assets/client/imgs/intro/video-preview-mobile.jpg';

import * as css from './Intro.scss';

// const scr = (scroll, id) => {
// 	const element = document.querySelectorAll(id)[0];
// 	const halfHeight = window.innerHeight / 3;
// 	return scroll + halfHeight >= element.offsetTop && scroll < element.offsetTop + element.offsetHeight - halfHeight;
// };

export default class Intro extends React.PureComponent {
	static propTypes = {
		className: PT.string,
		activeLanguage: PT.string,
	};

	static defaultProps = {
		className: '',
		activeLanguage: '',
	};

	constructor() {
		super();
		this.player = null;
		this.percent25 = false;
		this.percent50 = false;
		this.percent75 = false;
		this.percent90 = false;
		this.percent100 = false;
		this.enededVideo = false;
		this.videoRef = React.createRef();
	}

	state = {
		videoShow: false,
		// isIntro: false,
		// isVideo: false,
	};

	// componentDidMount() {
	// 	window.addEventListener('scroll', this.handleScroll);
	// }

	// componentWillUnmount() {
	// 	window.removeEventListener('scroll', this.handleScroll);
	// }

	// handleScroll = () => {
	// 	const scroll = window.pageYOffset || document.documentElement.scrollTop;
	// 	const intro = scr(scroll, '#intro');
	// 	const videoId = scr(scroll, '#video');

	// 	const { isIntro, isVideo } = this.state;

	// 	if (!isIntro && intro) {
	// 		this.setState({
	// 			isIntro: true,
	// 			isVideo: false,
	// 		});
	// 		analytics.onHashChange(analyticsLinksData[1].id, analyticsLinksData[1].gaTitle);
	// 		console.info('INTRO');
	// 	}

	// 	if (!isVideo && videoId) {
	// 		this.setState({
	// 			isIntro: false,
	// 			isVideo: true,
	// 		});
	// 		analytics.onHashChange(analyticsLinksData[2].id, analyticsLinksData[2].gaTitle);
	// 		console.info('VIDEO');
	// 	}
	// };

	componentDidMount() {
		this.videoRef.current.addEventListener('timeupdate', () => {
			this.timeUpdate(this.videoRef.current.duration, this.videoRef.current.currentTime);
		});

		this.videoRef.current.addEventListener('play', () => {
			this.playVideo();
		});

		this.videoRef.current.addEventListener('pause', () => {
			this.pauseVideo();
		});

		this.videoRef.current.addEventListener('ended', () => {
			this.endedVideo();
		});
	}

	timeUpdate = (duration, currentTime) => {
		const percents = (currentTime * 100) / duration;

		if (percents > 25 && percents < 50 && !this.percent25) {
			analytics.videoProgress('Played 25%');
			this.percent25 = true;
		}

		if (percents > 50 && percents < 75 && !this.percent50) {
			analytics.videoProgress('Played 50%');
			this.percent50 = true;
		}

		if (percents > 75 && percents < 90 && !this.percent75) {
			analytics.videoProgress('Played 75%');
			this.percent75 = true;
		}

		if (percents > 90 && percents < 100 && !this.percent90) {
			analytics.videoProgress('Played 90%');
			this.percent90 = true;
		}
	};

	endedVideo = () => {
		this.percent25 = false;
		this.percent50 = false;
		this.percent75 = false;
		this.percent90 = false;
		this.enededVideo = true;
		// console.log('ENDED VIDEO');
	};

	playVideo = () => {
		if (this.enededVideo) {
			analytics.videoProgress('Replay');
			this.enededVideo = false;
		} else {
			analytics.videoProgress('Play');
		}
	};

	pauseVideo = () => {
		analytics.videoProgress('Pause');
	};

	handlerVideo = () => {
		const video = this.videoRef.current;
		video.play();
		this.setState({ videoShow: true });
	};

	scrollDownClick = () => {
		const element = document.getElementById('video');

		setTimeout(() => {
			element.scrollIntoView();
		}, 300);
	};

	render() {
		const { className, activeLanguage } = this.props;
		const { intro } = data;
		const isDesktop = Responsive.isMatching(Responsive.DESKTOP);
		const videoPreview = isDesktop ? videoPreviewDesktop : videoPreviewMobile;
		const videoLink =
			// eslint-disable-next-line max-len
			'https://player.vimeo.com/external/390289128.hd.mp4?s=bc3bbc955a61d221592f08e91ee312c0534edee7&profile_id=174';
		if (!activeLanguage) return null;

		return (
			<div className={classNames(css.intro, className)}>
				<div id="top" className={css.infoSection}>
					<ImageGallery />
					<div className={css.infoContent}>
						<img src={logo} alt="logo-ynet" className={css.logo} />
						<h2>{intro[activeLanguage].infoTitle}</h2>
						<p>{intro[activeLanguage].infoTextUnderTitle}</p>
						<img
							className={css.scrollDownBtn}
							src={`${IMAGES_PATH}${intro.iconScrollDown}`}
							onClick={this.scrollDownClick}
							alt="logo"
						/>
					</div>
				</div>
				<div id="intro" className={css.introSection}>
					<ScrollAnimation animateOnce animateIn="fadeIn">
						<div className={css.videoDescription}>
							<div dangerouslySetInnerHTML={{ __html: intro[activeLanguage].videoText }} />
						</div>
					</ScrollAnimation>
				</div>
				<div id="video" className={css.videoSection}>
					<div className={css.videoWrap}>
						<ScrollAnimation animateOnce animateIn="fadeIn">
							<div className={css.videoPlayer}>
								<video controls="controls" src={videoLink} className={css.video} ref={this.videoRef} />
								{!this.state.videoShow && (
									<div className="wrap">
										<div
											className={css.play}
											onClick={this.handlerVideo}
											style={{
												backgroundImage: `url(${IMAGES_PATH}${intro.playIcon})`,
											}}
										/>
										<div className={css.posterTemp} style={bgImage(videoPreview)} />
									</div>
								)}
							</div>
						</ScrollAnimation>
					</div>
				</div>
			</div>
		);
	}
}
